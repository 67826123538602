/**
 * Function which checks, if the given dates are equal on a daily base. Returns true if yes, false otherwise
 * @param {Date} date1 - First date for the check
 * @param {Date} date2 - first date for the check
 */
export const isDateEqualDailyBase = function (date1, date2) {
  if (date1.getUTCFullYear() === date2.getUTCFullYear()) {
    if (date1.getUTCMonth() === date2.getUTCMonth()) {
      if (date1.getUTCDate() === date2.getUTCDate()) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Function which checks, if the given dates are equal on a daily base. Returns true if yes, false otherwise
 * @param {Date} date1 - First date for the check
 * @param {Date} date2 - first date for the check
 */
export const isDateEqualMonthlyBase = function (date1, date2) {
  if (date1.getUTCFullYear() === date2.getUTCFullYear()) {
    if (date1.getUTCMonth() === date2.getUTCMonth()) {
      return true;
    }
  }
  return false;
};

/**
 * Function returns year and calender week for a given date (DateGroup)
 * @param {Date} date - Date to calculate the DateGroup
 */
export const getCalenderWeekAndYearForDate = function (date) {
  // In JavaScript the Sunday has value 0 as return value of getDay() function.
  // So we have to order them first ascending from Monday to Sunday
  // Monday: ((1+6) % 7) = 0
  // Tuesday ((2+6) % 7) = 1
  // Wednesday: ((3+6) % 7) = 2
  // Thursday: ((4+6) % 7) = 3
  // Friday: ((5+6) % 7) = 4
  // Saturday: ((6+6) % 7) = 5
  // Sunday: ((0+6) % 7) = 6
  // (3 - result) is necessary to get the Thursday of the current week.
  // If we want to have Tuesday it would be (1-result)
  const currentThursday = new Date(
    date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000
  );

  // At the beginnig or end of a year the thursday could be in another year.
  const yearOfThursday = currentThursday.getFullYear();

  // Get first Thursday of the year
  const firstThursday = new Date(
    new Date(yearOfThursday, 0, 4).getTime() +
      (3 - ((new Date(yearOfThursday, 0, 4).getDay() + 6) % 7)) * 86400000
  );

  // +1 we start with week number 1
  // +0.5 an easy and dirty way to round result (in combinationen with Math.floor)
  let weekNumber = Math.floor(
    1 +
      0.5 +
      (currentThursday.getTime() - firstThursday.getTime()) / 86400000 / 7
  );

  //Check date (KW53?)
  if (weekNumber === 53 && date.getFullYear() !== yearOfThursday) {
    weekNumber = 1;
  }
  return `${date.getFullYear()}${String(weekNumber).padStart(2, 0)}`;
};

/**
 * Returns the Array with all elements for a given calender week (YYYYWW)
 * @param {Array} stakingData - Array to be filtered
 * @param {String} calenderWeek - Week to filter for (YYYYWW)
 */
export const getListForCalenderWeek = function (stakingData, calenderWeek) {
  return stakingData.filter(elem => {
    if (elem['DateGroup'] === calenderWeek) return true;
    return false;
  });
};

/**
 * Returns the Array with all elements for a given month (YYYYMM)
 * @param {Array} stakingData - Array to be filtered
 * @param {String} month - Week to filter for (YYYYMM)
 */
export const getListForMonth = function (stakingData, month) {
  return stakingData.filter(elem => {
    const testString = `${elem.Date.getFullYear()}-${String(
      elem.Date.getMonth() + 1
    ).padStart(2, 0)}`;
    if (testString.substr(0, 7) === month) return true;
    return false;
  });
};

/**
 * Returns the Array with all elements for a given date (YYYY-MM-DD)
 * @param {Array} stakingData - Array to be filtered
 * @param {String} day - Week to filter for (YYYY-MM-DD)
 */
export const getListForDays = function (stakingData, day) {
  return stakingData.filter(elem => {
    const testString = `${elem.Date.getFullYear()}-${String(
      elem.Date.getMonth() + 1
    ).padStart(2, 0)}-${String(elem.Date.getDate()).padStart(2, 0)}`;

    if (testString.substr(0, 10) === day) return true;
    return false;
  });
};
/**
 * Returns a unique list (Set ) of calenderweeks in the given data
 * @param {Array} stakingData - Array with the staking data to be analysed
 */
export const getListOfCalenderWeeks = function (stakingData) {
  let listOfCalenderWeeks = new Set();
  stakingData.forEach(elem => listOfCalenderWeeks.add(elem['DateGroup']));
  return listOfCalenderWeeks;
};

/**
 * Returns a unique list (Set) of month in the given data
 * @param {Array} stakingData - Array with the staking data to be analysed
 */
export const getListOfMonth = function (stakingData) {
  let listOfMonth = new Set();
  stakingData.forEach(elem => {
    const testDate = `${elem.Date.getFullYear()}-${String(
      elem.Date.getMonth() + 1
    ).padStart(2, 0)}`;
    listOfMonth.add(testDate);
  });
  return listOfMonth;
};

/**
 * Returns a unique list (Set) of Days in the given data
 * @param {Array} stakingData - Array with the staking data to be analysed
 */
export const getListOfDays = function (stakingData) {
  let listOfDays = new Set();
  stakingData.forEach(elem => {
    const testDate = `${elem.Date.getFullYear()}-${String(
      elem.Date.getMonth() + 1
    ).padStart(2, 0)}-${String(elem.Date.getDate()).padStart(2, 0)}`;
    listOfDays.add(testDate);
  });
  return listOfDays;
};

/**
 * Returns an filtered array with all elements
 * for a given year
 * @param {Array} data - Array to be filtered by date
 * @param {Number} year - Given year to filter the stakingData with
 */
export const getElementsByYear = function (data, year) {
  const startDate = new Date(`${year}-01-01T00:00:00`);
  const endDate = new Date(`${year}-12-31T23:59:59`);

  const retArr = data.filter(element => {
    const test = new Date(element['Date']);
    if (test >= startDate && test <= endDate) {
      return true;
    }
    return false;
  });
  return retArr;
};
/**
 * Returns the Date as a Accointing compatible String (01/20/2021 19:20:19)
 * @param {Date} date
 */
export const getDateString = function (date) {
  let dateStr = `${String(date.getMonth() + 1).padStart(2, 0)}/${String(
    date.getDate()
  ).padStart(2, 0)}/${date.getFullYear()} ${String(date.getHours()).padStart(
    2,
    0
  )}:${String(date.getMinutes()).padStart(2, 0)}:${String(
    date.getSeconds()
  ).padStart(2, 0)}`;
  return dateStr;
};

/**
 * Returns the Date as a Cryptotax compatible String (20.01.2021 19:20:19)
 * @param {Date} date
 */
export const getDateStringCryptotax = function (date) {
  let dateStr = `${String(date.getDate()).padStart(2, 0)}.${String(
    date.getMonth() + 1
  ).padStart(2, 0)}.${date.getFullYear()} ${String(date.getHours()).padStart(
    2,
    0
  )}:${String(date.getMinutes()).padStart(2, 0)}:${String(
    date.getSeconds()
  ).padStart(2, 0)}`;
  return dateStr;
};

/**
 * Returns the Date as a Cryptotax compatible String (2021-01-30 19:20:19)
 * @param {Date} date
 */
export const getDateStringBlockpit = function (date) {
  let dateStr = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    0
  )}-${String(date.getDate()).padStart(2, 0)} ${String(
    date.getHours()
  ).padStart(2, 0)}:${String(date.getMinutes()).padStart(2, 0)}:${String(
    date.getSeconds()
  ).padStart(2, 0)}`;
  return dateStr;
};
