import * as dateHelper from '../Helper/DateHelper.js';
import * as hlp from '../Helper/Helper.js';
import * as lmTool from './LMTools.js';
import * as actionHlp from '../Actions/Actions.js';
import * as mdl from '../model.js';

/**
 * Generates the defichain liquidity mining entries
 * @param {Array} data
 * @param {String} toolid
 * @param {Boolean} useToken
 */
export const generateDeFiLMActions = async function (data, toolid, useToken) {
  let csvString = '';
  let objData = [];
  let addDefiLMActions = lmTool.getAddDefiLMActions(data);
  let remDefiLMActions = lmTool.getRemDefiLMActions(data);
  if (useToken) {
    //Generate LM Actions for DeFiApp file
    if (addDefiLMActions.length > 0 || remDefiLMActions.length > 0) {
      hlp.logconsole('Generating LM actions for DeFi App');
      let allLiq = [...addDefiLMActions, ...remDefiLMActions];
      allLiq = hlp.removeDublicates(allLiq);
      //Set erstellen mit allen Blöcken, die involviert sind.
      const blockHeight = new Set();
      allLiq.forEach(el => {
        blockHeight.add(el.Block);
      });
      //Array erstellen mit allen Einträgen die zu dem Datum gehören
      blockHeight.forEach(blockEl => {
        let entryArr = allLiq.filter(entry => entry.Block === blockEl);

        //Must have length 3 (Coin1, Coin2, LM-Token)
        //Length === 3 --> Everything is fine!
        if (entryArr.length === 3) {
          let coinsAmount = [];
          let lmAmount;
          let coinsCur = [];
          let lmCur;
          const entryDate = entryArr[0].Date;
          if (entryArr[0].Operation === 'AddPoolLiquidity') {
            //<0 --> Coin || >0 --> Token
            entryArr.forEach(el => {
              if (el.Amount < 0) {
                coinsAmount.push(Math.abs(el.Amount).toFixed(16));
                coinsCur.push(el.Cryptocurrency);
              } else {
                lmAmount = Math.abs(el.Amount).toFixed(16);
                lmCur = el.Cryptocurrency;
              }
            });

            if (toolid === 'Accointing') {
              //Trade 1
              objData.push(
                actionHlp.getObjectFromData(
                  'order',
                  dateHelper.getDateString(entryDate),
                  (lmAmount / 2).toFixed(16),
                  lmCur,
                  coinsAmount[0],
                  coinsCur[0],
                  '',
                  '',
                  '',
                  'DefiChain Add Liquidity Trade 1'
                )
              );
              //Trade 2
              objData.push(
                actionHlp.getObjectFromData(
                  'order',
                  dateHelper.getDateString(entryDate),
                  (lmAmount / 2).toFixed(16),
                  lmCur,
                  coinsAmount[1],
                  coinsCur[1],
                  '',
                  '',
                  '',
                  'DefiChain Add Liquidity Trade 2'
                )
              );
            } else {
              csvString += hlp.getCSVLine(
                'Trade',
                lmAmount / 2,
                lmCur,
                coinsAmount[0],
                coinsCur[0],
                '',
                '',
                'DeFiChain Wallet',
                'Add Liquidity',
                'Trade 1',
                entryDate,
                `1${lmCur}${entryDate.toISOString()}`,
                '',
                toolid
              );
              csvString += hlp.getCSVLine(
                'Trade',
                lmAmount / 2,
                lmCur,
                coinsAmount[1],
                coinsCur[1],
                '',
                '',
                'DeFiChain Wallet',
                'Add Liquidity',
                'Trade 2',
                entryDate,
                `2${lmCur}${entryDate.toISOString()}`,
                '',
                toolid
              );
            }
          } else {
            //>0 --> Coin || <0 --> Token
            entryArr.forEach(el => {
              if (el.Amount > 0) {
                coinsAmount.push(Math.abs(el.Amount).toFixed(16));
                coinsCur.push(el.Cryptocurrency);
              } else {
                lmAmount = Math.abs(el.Amount).toFixed(16);
                lmCur = el.Cryptocurrency;
              }
            });
            //Trade zusammenstellen
            if (toolid === 'Accointing') {
              //Trade 1
              objData.push(
                actionHlp.getObjectFromData(
                  'order',
                  dateHelper.getDateString(entryDate),
                  coinsAmount[0],
                  coinsCur[0],
                  (lmAmount / 2).toFixed(16),
                  lmCur,
                  '',
                  '',
                  '',
                  'DeFiChain Wallet Remove Liquidity Trade 1'
                )
              );
              //Trade 2
              objData.push(
                actionHlp.getObjectFromData(
                  'order',
                  dateHelper.getDateString(entryDate),
                  coinsAmount[1],
                  coinsCur[1],
                  (lmAmount / 2).toFixed(16),
                  lmCur,
                  '',
                  '',
                  '',
                  'DeFiChain Wallet Remove Liquidity Trade 1'
                )
              );
            } else {
              csvString += hlp.getCSVLine(
                'Trade',
                coinsAmount[0],
                coinsCur[0],
                lmAmount / 2,
                lmCur,
                '',
                '',
                'DeFiChain Wallet',
                'Remove Liquidity',
                'Trade 1',
                entryDate,
                `1${lmCur}${entryDate.toISOString()}`,
                '',
                toolid
              );
              csvString += hlp.getCSVLine(
                'Trade',
                coinsAmount[1],
                coinsCur[1],
                lmAmount / 2,
                lmCur,
                '',
                '',
                'DeFiChain Wallet',
                'Remove Liquidity',
                'Trade 2',
                entryDate,
                `2${lmCur}${entryDate.toISOString()}`,
                '',
                toolid
              );
            }
          }
        }
      });
    }
  } else {
    //Do not use token and generate trades Coin --> FIAT --> Coin
    //Add Actions
    for (let i = 0; i < addDefiLMActions.length; i++) {
      const el = addDefiLMActions[i];
      if (el.Cryptocurrency.indexOf('-') === -1) {
        let fiatCur = 'EUR';
        let apiDate = new Date(
          Date.UTC(el.Date.getFullYear(), el.Date.getMonth(), el.Date.getDate())
        );

        let coinPrice = mdl.getPriceForDate(el.Cryptocurrency, apiDate, 'eur');
        //console.log(`Date: ${apiDate.getTime()} - Price: ${coinPrice}`);
        let coinAmount = Math.abs(el.Amount);
        let coinCur = el.Cryptocurrency;
        //console.log(`Amount: ${coinAmount} - Coin: ${coinCur}`);
        let fiatAmount = Math.abs(coinPrice * el.Amount).toFixed(2);
        //Trades zusammenstellen
        if (toolid === 'Accointing') {
          //Trade 1
          objData.push(
            actionHlp.getObjectFromData(
              'order',
              dateHelper.getDateString(el.Date),
              fiatAmount,
              fiatCur,
              coinAmount,
              coinCur,
              '',
              '',
              '',
              'DeFiChain Wallet Add Liquidity Coin to Fiat'
            )
          );
          objData.push(
            actionHlp.getObjectFromData(
              'order',
              dateHelper.getDateString(el.Date),
              coinAmount,
              coinCur,
              fiatAmount,
              fiatCur,
              '',
              '',
              '',
              'DeFiChain Wallet Add Liquidity Fiat to LM Coins'
            )
          );
        } else {
          csvString += hlp.getCSVLine(
            'Trade',
            fiatAmount,
            fiatCur,
            coinAmount,
            coinCur,
            '',
            '',
            'DeFiChain Wallet',
            'Add Liquidity',
            'Coin to Fiat',
            el.Date,
            `1${coinCur}${el.Date.toISOString()}`,
            '',
            toolid
          );
          csvString += hlp.getCSVLine(
            'Trade',
            coinAmount,
            coinCur,
            fiatAmount,
            fiatCur,
            '',
            '',
            'DeFiChain Wallet',
            'Add Liquidity',
            'Fiat to LM Coins',
            el.Date,
            `2${coinCur}${el.Date.toISOString()}`,
            '',
            toolid
          );
        }
      }
    }
    //Remove Actions
    //Alle Aktionen zusammenfassen
    const defiActions = [...addDefiLMActions, ...remDefiLMActions];
    //Alle 3 Einzeltaktionen in eine zusammenfassen (Coin1, Coin2, Token)
    //Set erstellen mit allen Blöcken, die involviert sind.
    const blockHeight = new Set();
    defiActions.forEach(el => {
      blockHeight.add(el.Block);
    });
    const lmActionsArr = [];
    //Array erstellen mit allen Einträgen die zu dem Datum gehören
    blockHeight.forEach(blockEl => {
      let sumArr = defiActions.filter(entry => entry.Block === blockEl);

      //Must have length 3 (Coin1, Coin2, LM-Token)

      if (sumArr.length === 3) {
        //console.log(sumArr);
        sumArr.forEach(el => {
          const tempEl = {};
          tempEl.Date = el.Date;
          let idxDFI = sumArr.findIndex(el => el.Cryptocurrency === 'DFI');
          let idxToken = sumArr.findIndex(
            el => el.Cryptocurrency.indexOf('-') !== -1
          );
          let idxCoin1 = sumArr.findIndex(
            el =>
              el.Cryptocurrency !== 'DFI' &&
              el.Cryptocurrency.indexOf('-') === -1
          );
          tempEl.Action = sumArr[idxToken].Amount < 0 ? 'remove' : 'add';
          tempEl.Token = sumArr[idxToken].Cryptocurrency;
          tempEl.TokenAmount = sumArr[idxToken].Amount;
          tempEl.Coin1 = sumArr[idxCoin1].Cryptocurrency;
          tempEl.Coin2 = sumArr[idxDFI].Cryptocurrency;
          tempEl.Coin1Amount = sumArr[idxCoin1].Amount;
          tempEl.Coin2Amount = sumArr[idxDFI].Amount;
          tempEl.TokenPriceCoin1 =
            sumArr[idxCoin1].Amount / sumArr[idxToken].Amount;
          tempEl.TokenPriceCoin2 =
            sumArr[idxDFI].Amount / sumArr[idxToken].Amount;

          lmActionsArr.push(tempEl);
        });
      }
    });
    //1.) Die Liquidity Aktionen müssen nach Datum aufsteigend sortiert werden
    lmActionsArr.sort(lmTool.sortDateAscending);
    //2.) Prüfen und ermitteln, ob es verschiedene Pools gibt
    const poolSet = new Set();
    lmActionsArr.forEach(el => poolSet.add(el.Token));
    const pools = Array.from(poolSet);
    //3.) Für jeden Pool muss das remove separat durchgeführt werden
    for (let i = 0; i < pools.length; i++) {
      let pool = pools[i];
      //console.log(pool);
      //Alle Aktionen für den aktuellen Pool ermitteln
      const lmPoolLmActions = lmActionsArr.filter(el => pool === el.Token);
      const remArrIdx = [];
      //Alle indizes von remove-Aktionen in einem Array speichern
      lmPoolLmActions.forEach((el, i) => {
        if (el.Action === 'remove') {
          remArrIdx.push(i);
        }
      });
      //Für alle remove Aktionen...
      for (let j = 0; j < remArrIdx.length; j++) {
        let el = remArrIdx[j];
        let c1Amnt = 0;
        let c2Amnt = 0;
        let tokenAmnt = 0;

        for (let k = 0; k < el; k++) {
          c1Amnt += Math.abs(lmPoolLmActions[k].Coin1Amount);
          c2Amnt += Math.abs(lmPoolLmActions[k].Coin2Amount);
          tokenAmnt += Math.abs(lmPoolLmActions[k].TokenAmount);
        }

        let c1SellAmnt = Math.abs(lmPoolLmActions[el].Coin1Amount);
        let c1 = lmPoolLmActions[el].Coin1;
        let c2SellAmnt = Math.abs(lmPoolLmActions[el].Coin2Amount);
        let c2 = lmPoolLmActions[el].Coin2;
        let date = lmPoolLmActions[el].Date;
        let c1Fiat = 0;
        let c2Fiat = 0;
        let fiatCur = 'EUR';
        let apiDate = new Date(
          Date.UTC(el.Date.getFullYear(), el.Date.getMonth(), el.Date.getDate())
        );

        let c1Price = mdl.getPriceForDate(c1, apiDate, 'eur');
        let c2Price = mdl.getPriceForDate(c2, apiDate, 'eur');
        //console.log(`Date: ${apiDate.getTime()} - Price: ${coinPrice}`);
        //console.log(`Price ${c1}: ${c1Price} ${c1Amnt}`);
        //console.log(`Price ${c2}: ${c2Price} ${c2Amnt}`);
        c1Fiat = c1Price * c1SellAmnt;
        c2Fiat = c2Price * c2SellAmnt;

        //Trades zusammenstellen
        if (toolid === 'Accointing') {
          //Trade coin1Amount --> FIAT
          //Trade coin2Amount --> FIAT
          objData.push(
            actionHlp.getObjectFromData(
              'order',
              dateHelper.getDateString(date),
              c1Fiat,
              fiatCur,
              c1SellAmnt,
              c1,
              '',
              '',
              '',
              'DeFiChain Wallet Remove Liquidity - Coin1 to Fiat'
            )
          );
          objData.push(
            actionHlp.getObjectFromData(
              'order',
              dateHelper.getDateString(date),
              c2Fiat,
              fiatCur,
              c2SellAmnt,
              c2,
              '',
              '',
              '',
              'DeFiChain Wallet Remove Liquidity - Coin2 to Fiat'
            )
          );
          objData.push(
            actionHlp.getObjectFromData(
              'order',
              dateHelper.getDateString(date),
              c1SellAmnt,
              c1,
              c1Fiat,
              fiatCur,
              '',
              '',
              '',
              'DeFiChain Wallet Remove Liquidity - Fiat to Coin1'
            )
          );
          objData.push(
            actionHlp.getObjectFromData(
              'order',
              dateHelper.getDateString(date),
              c2SellAmnt,
              c2,
              c2Fiat,
              fiatCur,
              '',
              '',
              '',
              'DeFiChain Wallet Remove Liquidity - Fiat to Coin2'
            )
          );
        } else {
          csvString += hlp.getCSVLine(
            'Trade',
            c1Fiat,
            fiatCur,
            c1SellAmnt,
            c1,
            '',
            '',
            'DeFiChain Wallet',
            'Remove Liquidity',
            'Coin1 to Fiat',
            date,
            `1${c1}${date.toISOString()}`,
            '',
            toolid
          );
          csvString += hlp.getCSVLine(
            'Trade',
            c2Fiat,
            fiatCur,
            c2SellAmnt,
            c2,
            '',
            '',
            'DeFiChain Wallet',
            'Remove Liquidity',
            'Coin2 to Fiat',
            date,
            `2${c2}${date.toISOString()}`,
            '',
            toolid
          );
          csvString += hlp.getCSVLine(
            'Trade',
            c1SellAmnt,
            c1,
            c1Fiat,
            fiatCur,
            '',
            '',
            'DeFiChain Wallet',
            'Remove Liquidity',
            'Fiat to Coin1',
            date,
            `1${c1}${date.toISOString()}`,
            '',
            toolid
          );
          csvString += hlp.getCSVLine(
            'Trade',
            c2SellAmnt,
            c2,
            c2Fiat,
            fiatCur,
            '',
            '',
            'DeFiChain Wallet',
            'Remove Liquidity',
            'Fiat to Coin2',
            date,
            `2${c2}${date.toISOString()}`,
            '',
            toolid
          );
        }
        //console.log(csvString);
        //Wieviele Coins hätte ich eigentlich bekommen müssen?
        //console.log(`Eingezahlt: ${c1Amnt} Auszahlen: ${c1SellAmnt} Token: ${tokenAmnt}`);
        let coin1Diff =
          (c1Amnt / tokenAmnt) * Math.abs(lmPoolLmActions[el].TokenAmount);
        let coin2Diff =
          (c2Amnt / tokenAmnt) * Math.abs(lmPoolLmActions[el].TokenAmount);
        coin1Diff = c1SellAmnt - coin1Diff;
        coin2Diff = c2SellAmnt - coin2Diff;
        let sellCoin;
        let sellCur;
        let buyCoin;
        let buyCur;
        if (coin1Diff < 0 && coin2Diff < 0) {
          if (toolid === 'Accointing') {
            objData.push(
              actionHlp.getObjectFromData(
                'withdraw',
                dateHelper.getDateString(date),
                '',
                '',
                Math.abs(coin1Diff),
                c1,
                '',
                '',
                'margin_loss',
                'DeFiChain Wallet Remove Liquidity - Impermanent loss compensation Coin 1'
              )
            );
            objData.push(
              actionHlp.getObjectFromData(
                'withdraw',
                dateHelper.getDateString(date),
                '',
                '',
                Math.abs(coin2Diff),
                c2,
                '',
                '',
                'margin_loss',
                'DeFiChain Wallet Remove Liquidity - Impermanent loss compensation Coin 2'
              )
            );
          } else {
            let operationId = 'Other fee';
            if (toolid === 'Blockpit') {
              operationId = 'Expenses';
            }
            csvString += hlp.getCSVLine(
              operationId,
              '',
              '',
              '',
              '',
              Math.abs(coin1Diff),
              c1,
              'DeFiChain Wallet',
              'Remove Liquidity',
              'Impermanent loss compensation',
              date,
              `${c1}${date.toISOString()}`,
              '',
              toolid
            );
            csvString += hlp.getCSVLine(
              operationId,
              '',
              '',
              '',
              '',
              Math.abs(coin2Diff),
              c2,
              'DeFiChain Wallet',
              'Remove Liquidity',
              'Impermanent loss compensation',
              date,
              `${c2}${date.toISOString()}`,
              '',
              toolid
            );
          }
        } else {
          if (coin1Diff < 0) {
            sellCoin = Math.abs(coin1Diff);
            sellCur = c1;
            buyCoin = Math.abs(coin2Diff);
            buyCur = c2;
          } else {
            sellCoin = Math.abs(coin2Diff);
            sellCur = c2;
            buyCoin = Math.abs(coin1Diff);
            buyCur = c1;
          }

          if (toolid === 'Accointing') {
            objData.push(
              actionHlp.getObjectFromData(
                'order',
                dateHelper.getDateString(date),
                buyCoin,
                buyCur,
                sellCoin,
                sellCur,
                '',
                '',
                '',
                'DeFiChain Wallet Remove Liquidity - Impermanent loss compensation'
              )
            );
          } else {
            csvString += hlp.getCSVLine(
              'Trade',
              buyCoin,
              buyCur,
              sellCoin,
              sellCur,
              '',
              '',
              'DeFiChain Wallet',
              'Remove Liquidity',
              'Impermanent loss compensation',
              date,
              `${sellCur}${date.toISOString()}`,
              '',
              toolid
            );
          }
        }
      }
    }
  }

  if (toolid === 'Accointing') {
    return objData;
  }
  return csvString;
};
