import * as dateHelper from '../Helper/DateHelper.js';
import * as Actions from '../Actions/Actions.js';
import * as lmTool from './LMTools.js';
import * as mdl from '../model.js';
import * as hlp from '../Helper/Helper.js';

/**
 * Generates all LM Actions for CAKE-File
 * @param {Array} data
 * @param {String} toolid
 * @param {Boolean} useToken
 * @returns csv or obj depending on requested toolid
 */
export const generateCakeLMActions = async function (data, toolid, useToken) {
  let csvString = '';
  let objData = [];

  //Get all CAKE actions with lm
  let addCakeLMCoinActions = lmTool.getAddCakeLMCoinActions(data);
  let addCakeLMTokenActions = lmTool.getAddCakeLMTokenActions(data);
  let remCakeLMCoinActions = lmTool.getRemCakeLMCoinActions(data);
  let remCakeLMTokenActions = lmTool.getRemCakeLMTokenActions(data);

  //Generate Liquidity Mining Actions
  if (useToken) {
    //c.1.) Generate LM Actions for CAKE V1
    if (addCakeLMCoinActions.length > 0 && addCakeLMTokenActions.length === 0) {
      //ACHTUNG: Use-Token ist hier nicht möglich!
      const err = `Old Cake format: Use of token is not possible here!`;
      console.error(`${err} 💥💥💥`);
      throw err;
    }
    hlp.logconsole('Generating LM actions for CAKE V2');
    let isError = false;
    addCakeLMTokenActions.forEach(token => {
      const ergArr = addCakeLMCoinActions.filter(el => {
        if (el.RelatedReference === token.Reference) {
          return true;
        }
        return false;
      });
      const lmAmount = Math.abs(token.Amount / 2);
      const lmCur = token.Cryptocurrency;

      if (ergArr.length === 2) {
        const coin1Amount = Math.abs(ergArr[0].Amount);
        const coin2Amount = Math.abs(ergArr[1].Amount);
        const coin1Cur = ergArr[0].Cryptocurrency;
        const coin2Cur = ergArr[1].Cryptocurrency;
        const elDate = token.Date;

        if (toolid === 'Accointing') {
          //Trade zusammenstellen
          //Trade 1
          objData.push(
            Actions.getObjectFromData(
              'order',
              dateHelper.getDateString(elDate),
              lmAmount,
              lmCur,
              coin1Amount,
              coin1Cur,
              '',
              '',
              '',
              'CAKE Liquidity Trade 1'
            )
          );
          //Trade 2
          objData.push(
            Actions.getObjectFromData(
              'order',
              dateHelper.getDateString(elDate),
              lmAmount,
              lmCur,
              coin2Amount,
              coin2Cur,
              '',
              '',
              '',
              'CAKE Add Liquidity Trade 2'
            )
          );
        } else {
          csvString += hlp.getCSVLine(
            'Trade',
            lmAmount,
            lmCur,
            coin1Amount,
            coin1Cur,
            '',
            '',
            'CAKE',
            'Add Liquidity',
            'Trade 1',
            elDate,
            `1${lmCur}${elDate.toISOString()}`,
            '',
            toolid
          );

          csvString += hlp.getCSVLine(
            'Trade',
            lmAmount,
            lmCur,
            coin2Amount,
            coin2Cur,
            '',
            '',
            'CAKE',
            'Add Liquidity',
            'Trade 2',
            elDate,
            `2${lmCur}${elDate.toISOString()}`,
            '',
            toolid
          );
        }
      } else {
        isError = true;
      }
    });
    /////////////////////////////////////////////////////////////////////////////////////
    //Remove
    remCakeLMTokenActions.forEach(token => {
      const ergArr = remCakeLMCoinActions.filter(
        el => el.RelatedReference === token.Reference
      );
      const lmAmount = Math.abs(token.Amount / 2);
      const lmCur = token.Cryptocurrency;
      if (ergArr.length === 2) {
        const coin1Amount = Math.abs(ergArr[0].Amount);
        const coin2Amount = Math.abs(ergArr[1].Amount);
        const coin1Cur = ergArr[0].Cryptocurrency;
        const coin2Cur = ergArr[1].Cryptocurrency;
        const elDate = token.Date;
        //Trade zusammenstellen
        if (toolid === 'Accointing') {
          //Trade 1
          objData.push(
            Actions.getObjectFromData(
              'order',
              dateHelper.getDateString(elDate),
              coin1Amount,
              coin1Cur,
              lmAmount,
              lmCur,
              '',
              '',
              '',
              'CAKE Remove Liquidity Trade 1'
            )
          );
          //Trade 2
          objData.push(
            Actions.getObjectFromData(
              'order',
              dateHelper.getDateString(elDate),
              coin2Amount,
              coin2Cur,
              lmAmount,
              lmCur,
              '',
              '',
              '',
              'CAKE Remove Liquidity Trade 1'
            )
          );
        } else {
          csvString += hlp.getCSVLine(
            'Trade',
            coin1Amount,
            coin1Cur,
            lmAmount,
            lmCur,
            '',
            '',
            'CAKE',
            'Remove Liquidity',
            'Trade 1',
            elDate,
            `1${lmCur}${elDate.toISOString()}`,
            '',
            toolid
          );
          csvString += hlp.getCSVLine(
            'Trade',
            coin2Amount,
            coin2Cur,
            lmAmount,
            lmCur,
            '',
            '',
            'CAKE',
            'Remove Liquidity',
            'Trade 2',
            elDate,
            `2${lmCur}${elDate.toISOString()}`,
            '',
            toolid
          );
        }
      } else {
        isError = true;
      }
    });
    if (isError) {
      const strErr = `!!!WARNING!!!\nThere are errors in the CAKE Export for Liquidity Mining.\nThis Bug is already reported to CAKE (issue 16810).\n\nThe defective lines are skipped!`;
      alert(strErr);
    }
  } else {
    //Do not use token and generate trades Coin --> FIAT --> Coins
    //ADD ACTIONS
    addCakeLMCoinActions.forEach(el => {
      let fiatAmount = Math.abs(el.FiatValue).toFixed(3);
      let fiatCur = el.FiatCurrency;
      let coinAmount = Math.abs(el.Amount.toFixed(16));
      let coinCur = el.Cryptocurrency;

      if (toolid === 'Accointing') {
        //Trade 1
        objData.push(
          Actions.getObjectFromData(
            'order',
            dateHelper.getDateString(el.Date),
            fiatAmount,
            fiatCur,
            coinAmount,
            coinCur,
            '',
            '',
            '',
            'CAKE Add Liquidity Coin to Fiat'
          )
        );
        objData.push(
          Actions.getObjectFromData(
            'order',
            dateHelper.getDateString(el.Date),
            coinAmount,
            coinCur,
            fiatAmount,
            fiatCur,
            '',
            '',
            '',
            'CAKE Add Liquidity Fiat to LM Coins'
          )
        );
      } else {
        csvString += hlp.getCSVLine(
          'Trade',
          fiatAmount,
          fiatCur,
          coinAmount,
          coinCur,
          '',
          '',
          'CAKE',
          'Add Liquidity',
          'Coin to Fiat',
          el.Date,
          `1${coinCur}${el.Date.toISOString()}`,
          '',
          toolid
        );
        csvString += hlp.getCSVLine(
          'Trade',
          coinAmount,
          coinCur,
          fiatAmount,
          fiatCur,
          '',
          '',
          'CAKE LM',
          'Add Liquidity',
          'Fiat to LM Coins',
          el.Date,
          `2${coinCur}${el.Date.toISOString()}`,
          '',
          toolid
        );
      }
    });

    //Alle Aktionen zusammenfassen
    const cakeTokenActions = [
      ...addCakeLMTokenActions,
      ...remCakeLMTokenActions,
    ];
    const cakeCoinActions = [...addCakeLMCoinActions, ...remCakeLMCoinActions];
    const lmActionsArr = [];
    cakeTokenActions.forEach(token => {
      const tempEl = {};
      const ergArr = cakeCoinActions.filter(
        el => el.RelatedReference === token.Reference
      );
      tempEl.Date = token.Date;
      tempEl.Action = token.Amount < 0 ? 'remove' : 'add';
      tempEl.Token = token.Cryptocurrency;
      tempEl.TokenAmount = token.Amount;
      if (ergArr[0].Cryptocurrency === 'DFI') {
        tempEl.Coin1 = ergArr[1].Cryptocurrency;
        tempEl.Coin2 = ergArr[0].Cryptocurrency;
        tempEl.Coin1Amount = ergArr[1].Amount;
        tempEl.Coin2Amount = ergArr[0].Amount;
        tempEl.TokenPriceCoin1 = ergArr[1].Amount / token.Amount;
        tempEl.TokenPriceCoin2 = ergArr[0].Amount / token.Amount;
      } else {
        tempEl.Coin1 = ergArr[0].Cryptocurrency;
        tempEl.Coin2 = ergArr[1].Cryptocurrency;
        tempEl.Coin1Amount = ergArr[0].Amount;
        tempEl.Coin2Amount = ergArr[1].Amount;
        tempEl.TokenPriceCoin1 = ergArr[0].Amount / token.Amount;
        tempEl.TokenPriceCoin2 = ergArr[1].Amount / token.Amount;
      }
      lmActionsArr.push(tempEl);
    });

    //1.) Die Liquidity Aktionen müssen nach Datum aufsteigend sortiert werden
    lmActionsArr.sort(lmTool.sortDateAscending);
    //2.) Prüfen und ermitteln, ob es verschiedene Pools gibt
    const poolSet = new Set();
    lmActionsArr.forEach(el => poolSet.add(el.Token));

    const pools = Array.from(poolSet);
    //3.) Für jeden Pool muss das remove separat durchgeführt werden
    for (let i = 0; i < pools.length; i++) {
      let pool = pools[i];
      //console.log(pool);
      //Alle Aktionen für den aktuellen Pool ermitteln
      const lmPoolLmActions = lmActionsArr.filter(el => pool === el.Token);
      const remArrIdx = [];
      //Alle indizes von remove-Aktionen in einem Array speichern
      lmPoolLmActions.forEach((el, i) => {
        if (el.Action === 'remove') {
          remArrIdx.push(i);
        }
      });
      //console.log(lmPoolLmActions);

      //Für alle remove Aktionen...
      for (let j = 0; j < remArrIdx.length; j++) {
        let el = remArrIdx[j];
        let c1Amnt = 0;
        let c2Amnt = 0;
        let tokenAmnt = 0;

        for (let k = 0; k < el; k++) {
          c1Amnt += Math.abs(lmPoolLmActions[k].Coin1Amount);
          c2Amnt += Math.abs(lmPoolLmActions[k].Coin2Amount);
          tokenAmnt += Math.abs(lmPoolLmActions[k].TokenAmount);
        }

        let c1SellAmnt = Math.abs(lmPoolLmActions[el].Coin1Amount);
        let c1 = lmPoolLmActions[el].Coin1;
        let c2SellAmnt = Math.abs(lmPoolLmActions[el].Coin2Amount);
        let c2 = lmPoolLmActions[el].Coin2;
        let date = lmPoolLmActions[el].Date;
        let c1Fiat = 0;
        let c2Fiat = 0;
        let fiatCur = 'EUR';
        let apiDate = new Date(
          Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
        );

        let c1Price = mdl.getPriceForDate(c1, apiDate, 'eur');
        let c2Price = mdl.getPriceForDate(c2, apiDate, 'eur');
        //console.log(`Price ${c1}: ${c1Price} ${c1Amnt}`);
        //console.log(`Price ${c2}: ${c2Price} ${c2Amnt}`);

        c1Fiat = c1Price * c1SellAmnt;
        c2Fiat = c2Price * c2SellAmnt;

        if (toolid === 'Accointing') {
          //Trade coin1Amount --> FIAT
          //Trade coin2Amount --> FIAT
          objData.push(
            Actions.getObjectFromData(
              'order',
              dateHelper.getDateString(date),
              c1Fiat,
              fiatCur,
              c1SellAmnt,
              c1,
              '',
              '',
              '',
              'CAKE Remove Liquidity - Coin1 to Fiat'
            )
          );
          objData.push(
            Actions.getObjectFromData(
              'order',
              dateHelper.getDateString(date),
              c2Fiat,
              fiatCur,
              c2SellAmnt,
              c2,
              '',
              '',
              '',
              'CAKE Remove Liquidity - Coin2 to Fiat'
            )
          );
          objData.push(
            Actions.getObjectFromData(
              'order',
              dateHelper.getDateString(date),
              c1SellAmnt,
              c1,
              c1Fiat,
              fiatCur,
              '',
              '',
              '',
              'CAKE Remove Liquidity - Fiat to Coin1'
            )
          );
          objData.push(
            Actions.getObjectFromData(
              'order',
              dateHelper.getDateString(date),
              c2SellAmnt,
              c2,
              c2Fiat,
              fiatCur,
              '',
              '',
              '',
              'CAKE Remove Liquidity - Fiat to Coin2'
            )
          );
        } else {
          csvString += hlp.getCSVLine(
            'Trade',
            c1Fiat,
            fiatCur,
            c1SellAmnt,
            c1,
            '',
            '',
            'CAKE',
            'Remove Liquidity',
            'Coin1 to Fiat',
            date,
            `1${c1}${date.toISOString()}`,
            '',
            toolid
          );
          csvString += hlp.getCSVLine(
            'Trade',
            c2Fiat,
            fiatCur,
            c2SellAmnt,
            c2,
            '',
            '',
            'CAKE',
            'Remove Liquidity',
            'Coin2 to Fiat',
            date,
            `2${c2}${date.toISOString()}`,
            '',
            toolid
          );
          csvString += hlp.getCSVLine(
            'Trade',
            c1SellAmnt,
            c1,
            c1Fiat,
            fiatCur,
            '',
            '',
            'CAKE',
            'Remove Liquidity',
            'Fiat to Coin1',
            date,
            `3${c1}${date.toISOString()}`,
            '',
            toolid
          );
          csvString += hlp.getCSVLine(
            'Trade',
            c2SellAmnt,
            c2,
            c2Fiat,
            fiatCur,
            '',
            '',
            'CAKE',
            'Remove Liquidity',
            'Fiat To Coin2',
            date,
            `4${c2}${date.toISOString()}`,
            '',
            toolid
          );
        }
        //Wieviele Coins hätte ich eigentlich bekommen müssen?
        //console.log(`Eingezahlt: ${c1Amnt} Auszahlen: ${c1SellAmnt} Token: ${tokenAmnt}`);
        //console.log(`Eingezahlt: ${c2Amnt} Auszahlen: ${c2SellAmnt}`);
        let coin1Diff =
          (c1Amnt / tokenAmnt) * Math.abs(lmPoolLmActions[el].TokenAmount);
        let coin2Diff =
          (c2Amnt / tokenAmnt) * Math.abs(lmPoolLmActions[el].TokenAmount);
        coin1Diff = c1SellAmnt - coin1Diff;
        coin2Diff = c2SellAmnt - coin2Diff;

        let sellCoin;
        let sellCur;
        let buyCoin;
        let buyCur;
        if (coin1Diff < 0 && coin2Diff < 0) {
          if (toolid === 'Accointing') {
            objData.push(
              Actions.getObjectFromData(
                'withdraw',
                dateHelper.getDateString(date),
                '',
                '',
                Math.abs(coin1Diff),
                c1,
                '',
                '',
                'margin_loss',
                'CAKE Remove Liquidity - Impermanent loss compensation Coin 1'
              )
            );
            objData.push(
              Actions.getObjectFromData(
                'withdraw',
                dateHelper.getDateString(date),
                '',
                '',
                Math.abs(coin2Diff),
                c2,
                '',
                '',
                'margin_loss',
                'CAKE Remove Liquidity - Impermanent loss compensation Coin 2'
              )
            );
          } else {
            let operationId = 'Other fee';
            if (toolid === 'Blockpit') {
              operationId = 'Expenses';
            }
            let toolCheck;
            if (toolid === 'Cointracking' || toolid === 'Koinly') {
              toolCheck = true;
            } else {
              toolCheck = false;
            }
            csvString += hlp.getCSVLine(
              operationId,
              '',
              '',
              toolCheck ? Math.abs(coin1Diff) : '',
              toolCheck ? c1 : '',
              toolCheck ? '' : Math.abs(coin1Diff),
              toolCheck ? '' : c1,
              'CAKE',
              'Remove Liquidity',
              'Impermanent loss compensation',
              date,
              `${c1}${date.toISOString()}`,
              '',
              toolid
            );

            csvString += hlp.getCSVLine(
              operationId,
              '',
              '',
              toolCheck ? Math.abs(coin2Diff) : '',
              toolCheck ? c2 : '',
              toolCheck ? '' : Math.abs(coin2Diff),
              toolCheck ? '' : c2,
              'CAKE',
              'Remove Liquidity',
              'Impermanent loss compensation',
              date,
              `${c2}${date.toISOString()}`,
              '',
              toolid
            );
          }
        } else {
          if (coin1Diff < 0) {
            sellCoin = Math.abs(coin1Diff);
            sellCur = c1;
            buyCoin = Math.abs(coin2Diff);
            buyCur = c2;
          } else {
            sellCoin = Math.abs(coin2Diff);
            sellCur = c2;
            buyCoin = Math.abs(coin1Diff);
            buyCur = c1;
          }

          if (toolid === 'Accointing') {
            objData.push(
              Actions.getObjectFromData(
                'order',
                dateHelper.getDateString(date),
                buyCoin,
                buyCur,
                sellCoin,
                sellCur,
                '',
                '',
                '',
                'CAKE Remove Liquidity - Impermanent loss compensation'
              )
            );
          } else {
            csvString += hlp.getCSVLine(
              'Trade',
              buyCoin,
              buyCur,
              sellCoin,
              sellCur,
              '',
              '',
              'CAKE',
              'Remove Liquidity',
              'Impermanent loss compensation',
              date,
              `${sellCur}${date.toISOString()}`,
              '',
              toolid
            );
          }
        }
      }
    }
  }
  if (toolid === 'Accointing') {
    return objData;
  }
  return csvString;
};
