import View from './View.js';
import * as domElems from './DOMElements.js';

class StatsView extends View {
  _parentElement = domElems.pricefeed;
  _errorMessage = '';
  _message = '';

  clearView() {
    this._clear();
  }
  updatePriceFeed(data, currency) {
    let htmlHeader = `
    <table class="pricefeed_table">
      <tbody>`;
    let htmlFooter = `
      </tbody>
    </table>`;

    let html = '';
    data.forEach(el => {
      html += `
      <tr>
        <th class="ticker">${el.ticker}</th>
        <th class="price">${el.price.toFixed(2)} ${currency}</th>
      </tr>`;
    });
    let erg = `${htmlHeader}${html}${htmlFooter}`;
    this._clear();
    this._parentElement.insertAdjacentHTML('afterbegin', erg);
  }
}

export default new StatsView();
