import * as cfg from '../Helper/config.js';

export const getAddCakeLMCoinActions = function (data) {
  return data.filter(el => cfg.CAKE_LM_ADD_COIN.includes(el.Operation));
};

export const getAddCakeLMTokenActions = function (data) {
  return data.filter(el => cfg.CAKE_LM_ADD_TOKEN.includes(el.Operation));
};

export const getRemCakeLMCoinActions = function (data) {
  return data.filter(el => cfg.CAKE_LM_REMOVE_COIN.includes(el.Operation));
};

export const getRemCakeLMTokenActions = function (data) {
  return data.filter(el => cfg.CAKE_LM_REMOVE_TOKEN.includes(el.Operation));
};

export const getAddDefiLMActions = function (data) {
  return data.filter(el => cfg.DEFI_LM_ADD.includes(el.Operation));
};
export const getRemDefiLMActions = function (data) {
  return data.filter(el => cfg.DEFI_LM_REMOVE.includes(el.Operation));
};

/**
 * Sorts an array of data by date
 * @param {Object} objA - Object A to check
 * @param {Object} objB - Object B to check
 * @returns the correct value for Array.sort
 */
export const sortDateAscending = function (objA, objB) {
  if (objA.Date.getTime() < objB.Date.getTime()) {
    return -1;
  }
  if (objA.Date.getTime() > objB.Date.getTime()) {
    return 1;
  }
  // a muss gleich b sein
  return 0;
};
