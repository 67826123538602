import * as fproc from './Files/FileProcessor.js';
import * as cfg from './Helper/config.js';
import * as api from '../API/coingecko.js';
import * as blockInfo from '../API/BlockInfo.js';
import * as CakeTrades from './Trades/CakeTrades.js';
import * as DeFiTrades from './Trades/DeFiTrades.js';
import * as CakeLM from './LiquidityMining/CakeLM.js';
import * as DeFiLM from './LiquidityMining/DeFiLM.js';
import * as Actions from './Actions/Actions.js';
import * as hlp from './Helper/Helper.js';
import * as overViewHelper from './Summary/Summary.js';
import * as dateHelper from './Helper/DateHelper.js';

/**
 * Object which saves the state of the application
 */
export const state = {
  currency: 'eur',
  files: [],
  fileCount: 0,
  completeData: [],
  fiatCur: '',
  fiatCurApi: '',
  coinDataArray: [],
  sumArray: [],
  csvReport: '',
};

export const clearState = function () {
  state.currency = 'eur';
  state.files = [];
  state.fileCount = 0;
  state.completeData = [];
  state.fiatCur = '';
  state.fiatCurApi = '';
  state.coinDataArray = [];
  state.sumArray = [];
  state.csvReport = '';
};

export const COIN_PRICEFEED = new Map();

/**
 * Builds up an obj with the historical data for all coins which are supported by defichain / cake
 */
export const getPriceFeed = async function () {
  //Get start and end date for the data to be processed
  let startDate = hlp.getStartDate(state.completeData) - 86400;
  const endDate = hlp.getEndDate(state.completeData) + 86400;

  //console.log(`Start End: ${startDate} - ${endDate}`);
  //If the timerange is smaller than 90 days, the pricefeed is on a hourly basis which is not working for now with the algorithm
  if (endDate - startDate < 7776000) {
    startDate = startDate - (7776000 - (endDate - startDate)) - 86400;
  }
  //console.log(`Start End: ${startDate} - ${endDate}`);
  for (let i = 0; i < cfg.COINLIST.length; i++) {
    const result = await api.getHistoricalDataForCoin(
      cfg.coinIdMap.get(cfg.COINLIST[i]),
      startDate,
      endDate,
      state.currency
    );
    const datePrice = new Map();
    result.prices.forEach(entry => {
      datePrice.set(entry[0], entry[1]);
    });
    COIN_PRICEFEED.set(cfg.COINLIST[i], datePrice);
  }
};

/**
 * Helper function to get the price for a given time stamp
 * @param {String} coinId - Coin id for the requested coin
 * @param {Date} date - Date object for the requested time
 * @returns the price for the given data
 */
export const getPriceForDate = function (coinId, date) {
  let erg = COIN_PRICEFEED.get(coinId).get(date.getTime());
  if (!erg) {
    //console.log(`Not found at date ${date.getTime()} for ${coinId} `);
    if (date.getTime() < 1593554400000) {
      if (coinId === 'DFI') {
        if (state.currency === 'usd') {
          erg = Number(0.1);
        } else if (state.currency === 'chf') {
          erg = Number(0.094);
        } else {
          erg = Number(0.089);
        }
      }
    }
  }
  return erg;
};

/**
 * Adds the prices to the dataset
 */
export const addPricefeedToDataset = function () {
  state.coinDataArray.forEach(coinData => {
    coinData.forEach(el => {
      let apiDate = new Date(
        Date.UTC(
          el['Date'].getFullYear(),
          el['Date'].getMonth(),
          el['Date'].getDate()
        )
      );
      el['FiatCurrencyAPI'] = state.currency;
      el['FiatPriceAPI'] = getPriceForDate(el['Cryptocurrency'], apiDate);
    });
  });
  state.fiatCurApi = state.currency;
};

/**
 * Returns the currency to be used (From API or CSV)
 * @param {String} cur
 * @returns the correct currency (API or from CSV)
 */
export const getFiatCurrency = function (cur) {
  if (cur === 'CAKE') {
    return state.fiatCur;
  } else {
    return state.fiatCurApi;
  }
};

/**
 * Checks, which currency shoul be used
 * @param {String} cur
 * @returns true if currency from csv and false if currency from API should be used
 */
export const isAPIUsed = function (cur) {
  if (cur === 'CAKE') {
    return false;
  } else {
    return true;
  }
};

/**
 * Loads and processes all given files asynchronously
 * @param {*} files - Array of files to be read in
 */
export const loadFiles = async function (files) {
  try {
    if (files) {
      let defiCounter = 0;
      hlp.startTimer('loadfiles');
      for (let i = 0; i < files.length; i++) {
        let fInfo = {};
        const file = files[i];
        fInfo.name = file.name;
        fInfo.size = file.size;
        fInfo.type = file.type;
        fInfo.lastModified = file.lastModified;
        let result = await fproc.readFile(file);
        hlp.logconsole('Loaded!');
        fInfo.typeOfFile = fproc.checkFileType(result.target.result);

        //ProcessFiles
        let erg = fproc.processFile(result.target.result, fInfo.typeOfFile);

        //Check if is old DEFIAPP_FILE
        if (fInfo.typeOfFile === cfg.DEFIAPP_FILE) {
          hlp.logconsole('Adding Dates because of old DefiApp-File');
          await blockInfo.addDateToData(erg);
        }
        if (
          fInfo.typeOfFile === cfg.DEFIAPP_FILE ||
          fInfo.typeOfFile === cfg.DEFIAPP_FILE_231 ||
          fInfo.typeOfFile === cfg.DEFIAPP_FILE_232
        ) {
          defiCounter++;
          //hlp.logconsole(`Anzahl Elemente: ${erg.length}`);
          //erg = reportHelper.removeDublicates(erg);
        }

        fInfo.data = erg;
        state.files.push(fInfo);
      }
      state.fileCount = files.length;
      let completeData = [];

      if (defiCounter > 1) {
        let defiData = [];
        state.files.forEach(el => {
          if (
            el.typeOfFile === cfg.DEFIAPP_FILE ||
            el.typeOfFile === cfg.DEFIAPP_FILE_231 ||
            el.typeOfFile === cfg.DEFIAPP_FILE_232
          ) {
            defiData = defiData.concat(el.data);
          } else {
            completeData = completeData.concat(el.data);
          }
        });
        let remDubl = reportHelper.removeDublicates(defiData);
        completeData = completeData.concat(remDubl);
      } else {
        state.files.forEach(el => {
          completeData = completeData.concat(el.data);
        });
      }
      state.completeData = completeData;
      state.fiatCur = completeData[0].FiatCurrency;
      hlp.endTimer('loadfiles');
    }
  } catch (err) {
    console.error(`${err} 💥💥💥`);
    throw err;
  }
};

/**
 * Separates the complete data in different arrays for each coin
 */
export const separateCoins = function () {
  try {
    //1.) Seperate Data for each coin
    const coinDataArray = [];
    cfg.COINLIST.forEach(coin => {
      coinDataArray.push(hlp.getDataForCoin(state.completeData, coin));
    });
    state.coinDataArray = coinDataArray;
    state.fiatCurApi = '';
  } catch (err) {
    console.error(`${err} 💥💥💥`);
    throw err;
  }
};

/**
 * Generates a summary of all earnings
 * @param {String} yearOfReport
 */
export const generateSummaryData = function (yearOfReport) {
  try {
    //1.) Check the requested report year
    let year = 2021;
    if (yearOfReport !== 'All') {
      year = Number(yearOfReport);
    }

    //2.) Build up the reward array
    const sumArray = [];
    for (let i = 0; i < state.coinDataArray.length; i++) {
      sumArray.push(overViewHelper.sumRewards(state.coinDataArray[i], year));
    }

    //3.) Store the summary in the state object
    state.sumArray = sumArray;
  } catch (err) {
    console.error(`${err} 💥💥💥`);
    throw err;
  }
};

/**
 * Check and returns the information about unsupported transactions
 * @returns the informationen if there are any unsupported transactions
 */
export const getUnsupportedTransactions = function () {
  return hlp.checkUnsupportedTransactions(state.completeData);
};

/**
 * Generates the csv-Report for the csv-based Cointracking Tools
 * @param {String} reportTool - Id of the cointracking to be used
 * @param {String} yearOfReport - The year for the report
 * @param {*String)
 */
export const generateCointrackerReport = async function () /*reportTool,
  yearOfReport,
  groupingType*/
{
  try {
    const tool = state.reportTool;
    const yearOfReport = state.yearOfReport;
    const groupingType = state.groupingType;
    const useToken = state.useToken;
    //0.) Check and report unsupported Transactions
    let reportData = [];
    let csvReport = '';
    let objReport = [];

    //1.) Get the elements for "yearOfReport"
    if (yearOfReport !== 'All') {
      reportData = dateHelper.getElementsByYear(
        state.completeData,
        yearOfReport
      );
    } else {
      reportData = state.completeData;
    }

    //2.) Check and correct data if grouping is day or month (incomplete data!)
    if (groupingType !== 'none') {
      reportData = hlp.checkAndCorrectData(
        reportData,
        new Date(state.files[0].lastModified),
        groupingType
      );
      state.completeData = reportData;
    }
    //3.) -----REPORT-----
    if (tool !== 'Accointing') {
      csvReport += cfg.CSVHEADER.get(tool);
      //c.) Generate Internal Trade actions
      csvReport += CakeTrades.generateCakeTrades(state.completeData, tool);
      csvReport += DeFiTrades.generateDeFiTrades(state.completeData, tool);
      //d.) Generate Liquidity Mining actions
      csvReport += await CakeLM.generateCakeLMActions(
        state.completeData,
        tool,
        useToken
      );
      csvReport += await DeFiLM.generateDeFiLMActions(
        state.completeData,
        tool,
        useToken
      );
      //e.) Separate Coins
      separateCoins();

      //f.) Generate actions for each coin
      state.coinDataArray.forEach(coinData => {
        if (coinData.length > 0) {
          csvReport += Actions.generateActions(coinData, tool, groupingType);
        }
      });
      const testStr = csvReport.charAt(csvReport.length - 1);
      if (testStr === '\n') {
        csvReport = csvReport.substr(0, csvReport.length - 1);
      }
      state.csvReport = csvReport;
    } else {
      //1.) Generate Internal Trade Actions
      objReport = objReport.concat(
        CakeTrades.generateCakeTrades(state.completeData, tool)
      );
      //console.log('Trades');
      //console.log(objReport);

      objReport = objReport.concat(
        DeFiTrades.generateDeFiTrades(state.completeData, tool)
      );

      //2.) Generate Liquidity Mining actions
      objReport = objReport.concat(
        await CakeLM.generateCakeLMActions(state.completeData, tool, useToken)
      );
      //console.log('LM');
      //console.log(objReport);
      objReport = objReport.concat(
        await DeFiLM.generateDeFiLMActions(state.completeData, tool, useToken)
      );

      //e.) Separate Coins
      separateCoins();
      //f.) Generate actions for each coin
      state.coinDataArray.forEach(coinData => {
        if (coinData.length > 0) {
          objReport = objReport.concat(
            Actions.generateActions(coinData, tool, groupingType)
          );
        }
      });

      const accointingWorkbook = XLSX.utils.book_new();
      const accointingHeader = {
        header: [
          'transactionType',
          'date',
          'inBuyAmount',
          'inBuyAsset',
          'outSellAmount',
          'outSellAsset',
          'feeAmount (optional)',
          'feeAsset (optional)',
          'classification (optional)',
          'operationId (optional)',
        ],
      };
      const ws = XLSX.utils.json_to_sheet(objReport, accointingHeader);

      XLSX.utils.book_append_sheet(accointingWorkbook, ws, 'Table1');
      XLSX.writeFile(accointingWorkbook, 'Accointing_report.xlsx');
    }
  } catch (err) {
    console.error(`${err} 💥💥💥`);
    throw err;
  }
};
