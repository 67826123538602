import * as dateHelper from '../Helper/DateHelper.js';
import * as Actions from '../Actions/Actions.js';
import * as hlp from '../Helper/Helper.js';

/**
 * Generates the report data (csv or obj) for all internal trade elements
 * @param {Array} data - Data to be analysed
 * @param {String} toolid - Which tool should be used
 * @returns csv or obj depending on requested toolid
 */
export const generateCakeTrades = function (data, toolid) {
  let csvString = '';
  let objData = [];
  hlp.logconsole('Generating Trade Actions');

  const inArr = data.filter(el => el.Operation === 'Swapped in');
  const outArr = data.filter(el => el.Operation === 'Swapped out');
  if (outArr.length !== inArr.length) {
    alert(
      `Error: Swapped in and Swapped out did not match!\n\nPlease ensure, that you selected all relevant files for upload!\n Hint: If you bought DFI Coins on Cake you have to upload the BTC and / or ETH CSV file as well!`
    );
  } else {
    //Check elements which belongs together
    inArr.forEach(iEl => {
      outArr.forEach(oEl => {
        const iAmount = Math.abs(iEl.Amount).toFixed(16);
        const oAmount = Math.abs(oEl.Amount).toFixed(16);
        const iCur = iEl.Cryptocurrency;
        const oCur = oEl.Cryptocurrency;
        const date = iEl.Date.toISOString();
        if (iEl.Date - oEl.Date >= -1000 && iEl.Date - oEl.Date <= 1000) {
          //Match found
          if (toolid === 'Accointing') {
            //Match found
            objData.push(
              Actions.getObjectFromData(
                'order',
                dateHelper.getDateString(iEl.Date),
                iAmount,
                iCur,
                oAmount,
                oCur,
                '',
                '',
                '',
                'CAKE internal coin swap'
              )
            );
          } else {
            csvString += hlp.getCSVLine(
              'Trade',
              iAmount,
              iCur,
              oAmount,
              oCur,
              '',
              '',
              'CAKE',
              'CAKE Internal Trade',
              'Currency Swap',
              iEl.Date,
              `${iCur}${oCur}${date}`,
              '',
              toolid
            );
          }
        }
      });
    });
  }
  if (toolid === 'Accointing') {
    return objData;
  }
  return csvString;
};
