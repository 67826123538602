import * as dateHelper from '../Helper/DateHelper.js';
import * as hlp from '../Helper/Helper.js';
import * as actionHlp from '../Actions/Actions.js';
/**
 * Generates the report data (csv or obj) for all internal trade elements
 * @param {Array} data - Data to be analysed
 * @param {String} toolid - Which tool should be used
 * @returns csv or obj depending on requested toolid
 */
export const generateDeFiTrades = function (data, toolid) {
  let csvString = '';
  let objData = [];
  hlp.logconsole('Generating Trade Actions');
  let swapArr = data.filter(el => el.Operation === 'PoolSwap');
  swapArr = hlp.removeDublicates(swapArr);

  //Set erstellen mit allen BlockHeights
  const blockHeights = new Set();
  swapArr.forEach(el => {
    blockHeights.add(el.Block);
  });
  //Array erstellen mit allen Einträgen die zu dem Datum gehören
  blockHeights.forEach(el => {
    let entryArr = swapArr.filter(entry => entry.Block === el);
    //FIXME: Achtung: Hier fehlt noch die Fehlerbehandlung!!!
    if (entryArr.length === 2) {
      const date = entryArr[0].Date;
      let inAmount;
      let outAmount;
      let inCur;
      let outCur;
      if (entryArr[0].Amount < 0) {
        outAmount = Math.abs(entryArr[0].Amount).toFixed(16);
        outCur = entryArr[0].Cryptocurrency;
        inAmount = Math.abs(entryArr[1].Amount).toFixed(16);
        inCur = entryArr[1].Cryptocurrency;
      } else {
        inAmount = Math.abs(entryArr[0].Amount).toFixed(16);
        inCur = entryArr[0].Cryptocurrency;
        outAmount = Math.abs(entryArr[1].Amount).toFixed(16);
        outCur = entryArr[1].Cryptocurrency;
      }

      if (toolid === 'Accointing') {
        objData.push(
          actionHlp.getObjectFromData(
            'order',
            dateHelper.getDateString(date),
            inAmount,
            inCur,
            outAmount,
            outCur,
            '',
            '',
            '',
            'DefiChain internal coin swap'
          )
        );
      } else {
        csvString += hlp.getCSVLine(
          'Trade',
          inAmount,
          inCur,
          outAmount,
          outCur,
          '',
          '',
          'DeFiChain Wallet',
          'DEX Trade',
          'Poolswap',
          date,
          `${inCur}${outCur}${date.toISOString()}`,
          '',
          toolid
        );
      }
    }
  });
  if (toolid === 'Accointing') {
    return objData;
  }
  return csvString;
};
