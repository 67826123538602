import * as api from '../../API/statsAPI.js';
import * as cfg from '../Helper/config.js';

let cachedPoolPairs = [];
let cachedLastPoolpairApiCall = 0;

let cachedOraclePrices = {};
let cachedLastOracleApiCall = 0;

/**
 * Gets the price of the token in the oracleData
 * @param {Object} oracleData
 * @param {String} tokenName
 * @returns the price
 */
const getPrice = function (oracleData, tokenName) {
  const erg = oracleData.find(el => el.id === tokenName);
  if (erg) {
    return erg.price.aggregated.amount;
  }
  return NaN;
};

/**
 * Gets the price of the given coin in currency from the oracle
 * @param {String} coin - Coin for which the price should be retrieved
 * @param {String} currency - Fiat curreny
 * @returns the Fiat value of the given coin
 */
export const getCoinPrice = async function (coin, currency) {
  try {
    const dateCheck = Date.now();
    let oracleData;
    if (dateCheck - cachedLastOracleApiCall < 120000) {
      oracleData = cachedOraclePrices;
    } else {
      oracleData = await api.getOraclePrices();
      cachedOraclePrices = oracleData;
      cachedLastOracleApiCall = dateCheck;
    }
    let priceCoin = getPrice(oracleData, `${coin}`);
    if (currency === 'eur') {
      const eurusd = getPrice(oracleData, 'EUR-USD');
      priceCoin = priceCoin / eurusd;
    }
    return priceCoin;
  } catch (err) {
    console.error(err);
    return NaN;
  }
};

/**
 * Calculates the price of the liquidity token in currency
 * @param {String} token - Token to be calculated
 * @param {String} currency - Fiat currency (eur or usd)
 * @returns the Fiat price for the given token
 */
export const getLMTokenPrice = async function (token, currency) {
  try {
    const dateCheck = Date.now();
    //Format BTC-DFI etc.
    const coins = token.split('-');
    const priceCoin1 = await getCoinPrice(`${coins[0]}-USD`, currency);
    const priceCoin2 = await getCoinPrice(`${coins[1]}-USD`, currency);
    //console.log(`${priceCoin1} -- ${priceCoin2}`);
    let poolPairs;
    if (dateCheck - cachedLastPoolpairApiCall < 120000) {
      poolPairs = cachedPoolPairs;
    } else {
      poolPairs = await api.getPoolPairInfos();
      cachedPoolPairs = poolPairs;
      cachedLastOracleApiCall = dateCheck;
    }
    const poolId = cfg.poolIdMap.get(coins[0]);
    const reserveA = poolPairs[poolId].reserveA;
    const reserveB = poolPairs[poolId].reserveB;
    const totalLiquidity = poolPairs[poolId].totalLiquidity;
    let tokenPrice =
      (reserveA * priceCoin1 + reserveB * priceCoin2) / totalLiquidity;

    return tokenPrice;
  } catch (err) {
    console.error(err);
    return NaN;
  }
};

/**
 * Gets the prices for the liquidity token
 * @param {String} cur -  Fiat currency for getting the price feed
 * @returns
 */
export const getLMPriceFeed = async function (cur) {
  const priceList = [];
  for (let i = 0; i < cfg.POOLS.length; i++) {
    let entry = {};
    entry.ticker = cfg.POOLS[i];
    entry.price = await getLMTokenPrice(entry.ticker, cur);
    priceList.push(entry);
  }
  return priceList;
};
