export const INFOLOGGING = true;
export const DEBUGLOGGING = true;
export const CAKE_FILE = 1;
export const DEFIAPP_FILE = 2;
export const DEFIPORTFOLIO_FILE = 3;
export const DEFIAPP_FILE_231 = 4;
export const DEFIAPP_FILE_232 = 5;
export const CAKE_FILE_20 = 6;
export const DEFIPORTFOLIO_FILE_NEW = 7;

export const COINLIST = [
  'BTC',
  'ETH',
  'DFI',
  'DASH',
  'XZC',
  'PIVX',
  'USDT',
  'LTC',
  'DOGE',
  'BCH',
  'USDC',
];

export const coinIdMap = new Map([
  ['BTC', 'bitcoin'],
  ['ETH', 'ethereum'],
  ['DFI', 'defichain'],
  ['DASH', 'dash'],
  ['XZC', 'zcoin'],
  ['PIVX', 'pivx'],
  ['USDT', 'tether'],
  ['LTC', 'litecoin'],
  ['DOGE', 'dogecoin'],
  ['BCH', 'bitcoin-cash'],
  ['USDC', 'usd-coin'],
]);

export const poolIdMap = new Map([
  ['ETH', '4'],
  ['BTC', '5'],
  ['USDT', '6'],
  ['DOGE', '8'],
  ['LTC', '10'],
  ['BCH', '12'],
  ['USDC', '14'],
]);

export const POOLS = [
  'ETH-DFI',
  'BTC-DFI',
  'USDT-DFI',
  'DOGE-DFI',
  'LTC-DFI',
  'BCH-DFI',
  'USDC-DFI',
];

export const FIAT_CURRENCY_MAP = new Map([
  ['eur', '€'],
  ['usd', '$'],
  ['chf', 'CHF'],
]);

export const CSVHEADER = new Map([
  [
    'Cointracking',
    `Type,Buy Amount,Buy Cur.,Sell Amount,Sell Cur.,Fee Amount (optional),Fee Cur. (optional),Exchange (optional),Trade Group (optional),Comment (optional),Date,Tx-ID\n`,
  ],
  [
    'Cryptotax',
    `exchange_name,account_name,trade_date,buy_asset,sell_asset,buy_amount,sell_amount,exchange_order_id,fee,fee_asset,transaction_type,clarification\n`,
  ],
  [
    'Blockpit',
    `;;;;;;;;;Version: 1.0\n;;;;;;;;;\nTransaction Type;From quantity;From currency;To quantity;To currency;Fees paid;Fee currency;Timestamp;Acquisition Timestamp;Note\n`,
  ],
  [
    'Koinly',
    `Date,Sent Amount,Sent Currency,Received Amount,Received Currency,Fee Amount,Fee Currency,Net Worth Amount,Net Worth Currency,Label,Description,TxHash\n`,
  ],
]);

export const GROUPABLE_ACTIONS = [
  'Staking reward',
  'Liquidity mining reward ETH-DFI',
  'Liquidity mining reward BTC-DFI',
  'Liquidity mining reward USDT-DFI',
  'Liquidity mining reward LTC-DFI',
  'Liquidity mining reward DOGE-DFI',
  'Liquidity mining reward BCH-DFI',
  'Liquidity mining reward USDC-DFI',
  'Rewards',
  'Commission',
  'Freezer staking bonus',
  'Freezer liquidity mining bonus',
  '10 years freezer reward',
  '5 years freezer reward',
];

export const NON_GROUPABLE_ACTIONS = [
  'Lapis DFI Bonus',
  'Lending DFI Bonus',
  'Lapis reward',
  'Lending reward',
  'Deposit',
  'Swapped in',
  'Swapped out',
  'Add liquidity ETH-DFI',
  'Add liquidity BTC-DFI',
  'Add liquidity USDT-DFI',
  'Add liquidity LTC-DFI',
  'Add liquidity DOGE-DFI',
  'Add liquidity BCH-DFI',
  'Add liquidity USDC-DFI',
  'Withdrawal',
  'Bonus/Airdrop',
  'Withdrawal fee',
  'Unstake fee',
  'Signup bonus',
  'Remove liquidity ETH-DFI',
  'Remove liquidity BTC-DFI',
  'Remove liquidity USDT-DFI',
  'Remove liquidity LTC-DFI',
  'Remove liquidity DOGE-DFI',
  'Remove liquidity BCH-DFI',
  'Remove liquidity USDC-DFI',
  'Referral signup bonus',
  'Confectionery Lapis DFI Bonus',
  'Confectionery Lending DFI Bonus',
  'Referral reward',
  'Added liquidity',
  'Removed liquidity',
  'Claimed for 50% discount',
  'Used for 50% discount',
  'Paid deposit fee',
  'receive',
  'PoolSwap',
  'AddPoolLiquidity',
  'RemovePoolLiquidity',
  'UtxosToAccount',
  'AccountToAccount',
  'AnyAccountsToAccounts',
  'AccountToUtxos',
  'sent',
  'blockReward',
  '',
  'Custom transaction by the Cake team',
  'Unknown',
  'Freezer promotion bonus',
  'Address creation fee',
  'Promotion bonus',
];

export const CAKE_LM_ADD_COIN = [
  'Add liquidity ETH-DFI',
  'Add liquidity BTC-DFI',
  'Add liquidity USDT-DFI',
  'Add liquidity LTC-DFI',
  'Add liquidity DOGE-DFI',
  'Add liquidity BCH-DFI',
  'Add liquidity USDC-DFI',
];

export const CAKE_LM_ADD_TOKEN = ['Added liquidity'];

export const CAKE_LM_REMOVE_COIN = [
  'Remove liquidity ETH-DFI',
  'Remove liquidity BTC-DFI',
  'Remove liquidity USDT-DFI',
  'Remove liquidity LTC-DFI',
  'Remove liquidity DOGE-DFI',
  'Remove liquidity BCH-DFI',
  'Remove liquidity USDC-DFI',
];
export const CAKE_LM_REMOVE_TOKEN = ['Removed liquidity'];

export const DEFI_LM_ADD = ['AddPoolLiquidity'];
export const DEFI_LM_REMOVE = ['RemovePoolLiquidity'];

export const CSVMAPPING = new Map([
  [
    'Staking reward',
    {
      cointrackingid: 'Staking',
      blockpitid: 'Staking',
      cryptotaxid: 'staking',
      accointing: 'staked',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Freezer staking bonus',
    {
      cointrackingid: 'Staking',
      blockpitid: 'Staking',
      cryptotaxid: 'staking',
      accointing: 'staked',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    '10 years freezer reward',
    {
      cointrackingid: 'Staking',
      blockpitid: 'Staking',
      cryptotaxid: 'staking',
      accointing: 'staked',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    '5 years freezer reward',
    {
      cointrackingid: 'Staking',
      blockpitid: 'Staking',
      cryptotaxid: 'staking',
      accointing: 'staked',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Freezer liquidity mining bonus',
    {
      cointrackingid: 'Mining',
      blockpitid: 'Mining',
      cryptotaxid: 'mining',
      accointing: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    '',
    {
      cointrackingid: 'Reward / Bonus',
      blockpitid: 'Gift Received',
      cryptotaxid: '',
      accointing: 'gift_received',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Liquidity mining reward ETH-DFI',
    {
      cointrackingid: 'Mining',
      blockpitid: 'Mining',
      cryptotaxid: 'mining',
      accointing: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Liquidity mining reward BTC-DFI',
    {
      cointrackingid: 'Mining',
      blockpitid: 'Mining',
      cryptotaxid: 'mining',
      accointing: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Liquidity mining reward USDT-DFI',
    {
      cointrackingid: 'Mining',
      blockpitid: 'Mining',
      cryptotaxid: 'mining',
      accointing: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Liquidity mining reward LTC-DFI',
    {
      cointrackingid: 'Mining',
      blockpitid: 'Mining',
      cryptotaxid: 'mining',
      accointing: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Liquidity mining reward DOGE-DFI',
    {
      cointrackingid: 'Mining',
      blockpitid: 'Mining',
      cryptotaxid: 'mining',
      accointing: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Liquidity mining reward BCH-DFI',
    {
      cointrackingid: 'Mining',
      blockpitid: 'Mining',
      cryptotaxid: 'mining',
      accointing: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Liquidity mining reward USDC-DFI',
    {
      cointrackingid: 'Mining',
      blockpitid: 'Mining',
      cryptotaxid: 'mining',
      accointing: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Bonus/Airdrop',
    {
      cointrackingid: 'Airdrop',
      blockpitid: 'Airdrop',
      cryptotaxid: 'airdrop',
      accointing: 'airdrop',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Deposit',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'Deposit',
      cryptotaxid: '',
      accointing: '',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Lapis reward',
    {
      cointrackingid: 'Lending Income',
      blockpitid: 'Lending',
      cryptotaxid: 'lending',
      accointing: 'lending_income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Lending reward',
    {
      cointrackingid: 'Lending Income',
      blockpitid: 'Lending',
      cryptotaxid: 'lending',
      accointing: 'lending_income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Lapis DFI Bonus',
    {
      cointrackingid: 'Reward / Bonus',
      blockpitid: 'Lending',
      cryptotaxid: 'lending',
      accointing: 'lending_income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Lending DFI Bonus',
    {
      cointrackingid: 'Reward / Bonus',
      blockpitid: 'Lending',
      cryptotaxid: 'lending',
      accointing: 'lending_income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Withdrawal',
    {
      cointrackingid: 'Withdrawal',
      blockpitid: 'Withdrawal',
      cryptotaxid: '',
      accointing: '',
      inout: false,
      deposit: false,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Withdrawal fee',
    {
      cointrackingid: 'Other Fee',
      blockpitid: 'Expenses',
      cryptotaxid: '',
      accointing: 'fee',
      inout: false,
      deposit: false,
      fee: true,
      exchange: 'CAKE',
    },
  ],
  [
    'Unstake fee',
    {
      cointrackingid: 'Other Fee',
      blockpitid: 'Expenses',
      cryptotaxid: '',
      accointing: 'fee',
      inout: false,
      deposit: false,
      fee: true,
      exchange: 'CAKE',
    },
  ],
  [
    'Address creation fee',
    {
      cointrackingid: 'Other Fee',
      blockpitid: 'Expenses',
      cryptotaxid: '',
      accointing: 'fee',
      inout: false,
      deposit: false,
      fee: true,
      exchange: 'CAKE',
    },
  ],
  [
    'Signup bonus',
    {
      cointrackingid: 'Reward / Bonus',
      blockpitid: 'Gift Received',
      cryptotaxid: '',
      accointing: 'gift_received',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Referral signup bonus',
    {
      cointrackingid: 'Reward / Bonus',
      blockpitid: 'Gift Received',
      cryptotaxid: '',
      accointing: 'gift_received',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Promotion bonus',
    {
      cointrackingid: 'Reward / Bonus',
      blockpitid: 'Gift Received',
      cryptotaxid: '',
      accointing: 'gift_received',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Confectionery Lapis DFI Bonus',
    {
      cointrackingid: 'Reward / Bonus',
      blockpitid: 'Lending',
      cryptotaxid: 'lending',
      accointing: 'lending_income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Confectionery Lending DFI Bonus',
    {
      cointrackingid: 'Reward / Bonus',
      blockpitid: 'Lending',
      cryptotaxid: 'lending',
      accointing: 'lending_income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Referral reward',
    {
      cointrackingid: 'Reward / Bonus',
      blockpitid: 'Gift Received',
      cryptotaxid: '',
      accointing: 'gift_received',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Paid deposit fee',
    {
      cointrackingid: 'Other Fee',
      blockpitid: 'Expenses',
      cryptotaxid: '',
      accointing: 'fee',
      inout: false,
      deposit: false,
      fee: true,
      exchange: 'CAKE',
    },
  ],

  [
    'Claimed for 50% discount',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'Deposit',
      cryptotaxid: '',
      accointing: '',
      inout: true,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Used for 50% discount',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'Deposit',
      cryptotaxid: '',
      accointing: '',
      inout: true,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Custom transaction by the Cake team',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'Deposit',
      cryptotaxid: '',
      accointing: '',
      inout: true,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Unknown',
    {
      cointrackingid: 'Reward / Bonus',
      blockpitid: 'Gift Received',
      cryptotaxid: '',
      accointing: 'gift_received',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Freezer promotion bonus',
    {
      cointrackingid: 'Reward / Bonus',
      blockpitid: 'Gift Received',
      cryptotaxid: '',
      accointing: 'gift_received',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'AccountToAccount',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'Deposit',
      cryptotaxid: '',
      accointing: '',
      inout: true,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'AnyAccountsToAccounts',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'Deposit',
      cryptotaxid: '',
      accointing: '',
      inout: true,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'Commission',
    {
      cointrackingid: 'Mining',
      blockpitid: 'Mining',
      cryptotaxid: 'mining',
      accointing: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'Rewards',
    {
      cointrackingid: 'Mining',
      blockpitid: 'Mining',
      cryptotaxid: 'mining',
      accointing: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'receive',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'Deposit',
      cryptotaxid: '',
      accointing: '',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'sent',
    {
      cointrackingid: 'Withdrawal',
      blockpitid: 'Withdrawal',
      cryptotaxid: '',
      accointing: '',
      inout: false,
      deposit: false,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'blockReward',
    {
      cointrackingid: 'Staking',
      blockpitid: 'Staking',
      cryptotaxid: 'staking',
      accointing: 'staked',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
]);
