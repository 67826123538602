import * as cfg from '../Helper/config.js';
import * as dateHelper from '../Helper/DateHelper.js';
import * as hlp from '../Helper/Helper.js';

/**
 * Groups the data and returns it
 * @param {Array} data
 * @param {String} groupingType - 'none', 'day' or 'month'
 * @returns the grouped entries
 */
export const getGroupedData = function (data, groupingType) {
  const retArr = [];
  //1.) Get the data which is groupable
  let groupedData = hlp.getGroupableData(data);

  //2.) Group the data
  if (groupingType === 'none') {
    groupedData.forEach(arr => {
      arr.forEach(el => {
        retArr.push(el);
      });
    });
  }
  if (groupingType === 'day') {
    groupedData.forEach(arr => {
      dateHelper.getListOfDays(arr).forEach(el => {
        let erg = dateHelper.getListForDays(arr, el);
        retArr.push(hlp.getGroupedEntry(erg, ''));
      });
    });
  }
  if (groupingType === 'month') {
    groupedData.forEach(arr => {
      dateHelper.getListOfMonth(arr).forEach(el => {
        let erg = dateHelper.getListForMonth(arr, el);
        retArr.push(hlp.getGroupedEntry(erg, ''));
      });
    });
  }
  return retArr;
};

///////////////////////////////////////////////////////////////////////////////
/**
 * Builds an object with all given parameters and returns it
 * @param {String} transaction
 * @param {String} date
 * @param {Number} inAmount
 * @param {String} inAsset
 * @param {Number} outAmount
 * @param {String} outAsset
 * @param {Number} feeAmount
 * @param {String} feeAsset
 * @param {String} classId
 * @param {String} opId
 */
export const getObjectFromData = function (
  transaction,
  date,
  inAmount,
  inAsset,
  outAmount,
  outAsset,
  feeAmount,
  feeAsset,
  classId,
  opId
) {
  let elem = {};
  elem['transactionType'] = transaction;
  elem['date'] = date;
  elem['inBuyAmount'] = inAmount;
  elem['inBuyAsset'] = inAsset;
  elem['outSellAmount'] = outAmount;
  elem['outSellAsset'] = outAsset;
  elem['feeAmount (optional)'] = feeAmount;
  elem['feeAsset (optional)'] = feeAsset;
  elem['classification (optional)'] = classId;
  elem['operationId (optional)'] = opId;

  return elem;
};

/**
 * Removes unneeded token transactions (AccountToAccount, AnyAccountsToAccounts)
 * @param {Array} data - Data to be checked
 * @returns the array with "external" token transactionen
 */
const getNeededTokenTransactions = function (data) {
  const newArr = [];
  let bFound = false;
  data.forEach(checkEl => {
    bFound = false;
    data.forEach(el => {
      if (checkEl.Amount + el.Amount === 0) {
        bFound = true;
      }
    });
    if (!bFound) {
      newArr.push(checkEl);
    }
  });
  return newArr;
};

/**
 * Generates report for all action (csv or obj) for the given data
 * @param {Array} coinData - Data array with the data to be processed
 * @param {String} reportTool - Which tool to be used.
 * @param {String} groupingType - the grouping type. 'day', 'month' or 'none'
 * @returns the array with all action objects
 */
export const generateActions = function (coinData, toolid, groupingType) {
  if (coinData.length === 0) return '';

  const accountActionsArr = getNeededTokenTransactions(
    coinData.filter(el => el.Operation === 'AccountToAccount')
  );

  const anyAccountActionsArr = getNeededTokenTransactions(
    coinData.filter(el => el.Operation === 'AnyAccountsToAccounts')
  );

  const sentReceiveActionsArr = getNeededTokenTransactions(
    coinData.filter(el => {
      if (el.Operation === 'sent' || el.Operation === 'receive') {
        return true;
      } else {
        return false;
      }
    })
  );
  const filteredCoinData = coinData.filter(el => {
    if (
      el.Operation === 'AccountToAccount' ||
      el.Operation === 'AnyAccountsToAccounts' ||
      el.Operation === 'sent' ||
      el.Operation === 'receive'
    ) {
      return false;
    }
    return true;
  });

  const newArr = [
    ...getGroupedData(filteredCoinData, groupingType),
    ...hlp.getNonGroupableData(filteredCoinData),
    ...accountActionsArr,
    ...anyAccountActionsArr,
    ...sentReceiveActionsArr,
  ];
  let objData = [];
  let csvString = '';
  newArr.forEach(data => {
    const opCfg = cfg.CSVMAPPING.get(data.Operation);
    if (!opCfg) return '';

    const isNegative = data.Amount < 0;
    const amount = Math.abs(data.Amount).toFixed(16);
    let cur = data.Cryptocurrency;
    const opId = data.Operation;

    if (data.Cryptocurrency === 'XZC') {
      cur = 'FIRO';
    }

    //inAmount, inCur, outAmount, outCur, feeAmount, feeCur
    let valArr = ['', '', '', '', '', ''];
    if (opCfg.inout) {
      if (isNegative) {
        opCfg.deposit = false;
      } else {
        opCfg.deposit = true;
      }
    }
    if (opCfg.fee) {
      valArr = ['', '', '', '', amount, cur];
    } else {
      if (opCfg.deposit) {
        valArr = [amount, cur, '', '', '', ''];
      } else {
        valArr = ['', '', amount, cur, '', ''];
      }
    }
    /*`Type,Buy Amount,Buy Cur.,Sell Amount,Sell Cur.,Fee Amount (optional),Fee Cur. (optional),Exchange (optional),Trade Group (optional),Comment (optional),Date,Tx-ID` */
    if (toolid === 'Cointracking') {
      if (opCfg.fee) {
        valArr = ['', '', amount, cur, '', ''];
      }
      if (opCfg.inout === true) {
        if (opCfg.deposit) {
          opCfg.cointrackingid = 'Deposit';
        } else {
          opCfg.cointrackingid = 'Withdrawal';
        }
      }

      csvString += `"${opCfg.cointrackingid}","${valArr[0]}","${valArr[1]}","${
        valArr[2]
      }","${valArr[3]}","${valArr[4]}","${valArr[5]}","${
        opCfg.exchange
      }","","${opId}","${data.Date.toISOString()}","${cur}${data.Date.toISOString()}"\n`;
    }
    /*`exchange_name,account_name,trade_date,buy_asset,sell_asset,buy_amount,sell_amount,exchange_order_id,fee,fee_asset,transaction_type,clarification */
    if (toolid === 'Cryptotax') {
      let type = '';
      if (opCfg.deposit) {
        type = 'deposit';
      } else {
        type = 'withdrawal';
      }
      csvString += `"${
        opCfg.exchange
      }","Default","${dateHelper.getDateStringCryptotax(data.Date)}","${
        valArr[1]
      }","${valArr[3]}","${valArr[0]}","${valArr[2]}","${opId}","${
        valArr[4]
      }","${valArr[5]}","${type}","${opCfg.cryptotaxid}"\n`;
    }
    /*`;;;;;;;;;Version: 1.0\n
     ;;;;;;;;;\n
     Transaction Type;From quantity;From currency;To quantity;To currency;Fees paid;Fee currency;Timestamp;Acquisition Timestamp;Note\n` */
    if (toolid === 'Blockpit') {
      if (opCfg.inout === true) {
        if (opCfg.deposit) {
          opCfg.blockpitid = 'Deposit';
        } else {
          opCfg.blockpitid = 'Withdrawal';
        }
      }
      csvString += `${opCfg.blockpitid};${valArr[2]};${valArr[3]};${
        valArr[0]
      };${valArr[1]};${valArr[4]};${
        valArr[5]
      };${dateHelper.getDateStringBlockpit(data.Date)};;${opId}\n`;
    }
    if (toolid === 'Koinly') {
      //Date,Sent Amount,Sent Currency,Received Amount,Received Currency,Fee Amount,Fee Currency,Net Worth Amount,Net Worth Currency,Label,Description,TxHash
      if (opCfg.fee) {
        valArr = ['', '', amount, cur, '', ''];
      }
      csvString += `${data.Date.toISOString()},${valArr[2]},${valArr[3]},${
        valArr[0]
      },${valArr[1]},${valArr[4]},${valArr[5]},,,,${opId},\n`;
    }
    if (toolid === 'Accointing') {
      let type = '';
      if (opCfg.deposit) {
        type = 'deposit';
      } else {
        type = 'withdraw';
      }
      if (opCfg.fee) {
        valArr = ['', '', amount, cur, '', ''];
      }
      objData.push(
        getObjectFromData(
          type,
          dateHelper.getDateString(data.Date),
          valArr[0],
          valArr[1],
          valArr[2],
          valArr[3],
          valArr[4],
          valArr[5],
          opCfg.accointing,
          opId
        )
      );
    }
  });
  if (toolid === 'Accointing') {
    return objData;
  }
  return csvString;
};
