import icons from 'url:../../../vendors/fonts/icons.svg'; // Parcel 2
import * as domElems from './DOMElements.js';

export default class View {
  _clear() {
    this._parentElement.innerHTML = '';
  }

  render(data) {
    //Render data
    this._clear();
  }

  renderSpinner() {
    const markup = `
      <div class="spinner">
        <svg>
          <use href="${icons}#icon-loader"></use>
        </svg>
      </div>
    `;

    this._clear();
    this._parentElement.insertAdjacentHTML('afterbegin', markup);
  }

  renderAlert(message) {
    alert(message);
  }

  renderError(message = this._errorMessage) {
    const markup = `
      <div class="error">
        <p>${message}</p>
      </div>
    `;
    this._clear();
    this._parentElement.insertAdjacentHTML('afterbegin', markup);
  }

  renderMessage(message = this._message) {
    const markup = `
      <div class="message">  
        <p>${message}</p>
      </div>
    `;
    this._clear();
    this._parentElement.insertAdjacentHTML('afterbegin', markup);
  }

  initHeaderNavigation() {
    // 1. Add event listener to common parent element
    // 2. Determine what element originated the event
    document.querySelector('.main-nav').addEventListener('click', function (e) {
      e.preventDefault();

      // Matching strategy
      if (e.target.classList.contains('nav--link')) {
        const id = e.target.getAttribute('href');
        const scrollVal = document
          .querySelector(id)
          .getBoundingClientRect().top;
        window.scrollBy({ top: scrollVal - 130, left: 0, behavior: 'smooth' });
      }
    });
  }

  initFooterNavigation() {
    document
      .querySelector('.footer-nav')
      .addEventListener('click', function (e) {
        e.preventDefault();

        // Matching strategy
        if (e.target.classList.contains('nav--link')) {
          const id = e.target.getAttribute('href');
          const scrollVal = document
            .querySelector(id)
            .getBoundingClientRect().top;
          window.scrollBy({
            top: scrollVal - 130,
            left: 0,
            behavior: 'smooth',
          });
        }
      });
  }

  initModal() {
    // Get the modal
    const modal = document.getElementById('ReleaseNotes');

    // Get the button that opens the modal
    const btn = document.getElementById('btnReleaseNotes');

    // Get the <span> element that closes the modal
    const span = document.getElementsByClassName('close')[0];

    // When the user clicks on the button, open the modal
    btn.onclick = function () {
      modal.style.display = 'block';
    };

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = 'none';
    };

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = 'none';
      }
    };
  }

  getFIATCurrency() {
    return document.querySelector('#currency-id').value;
  }
}
