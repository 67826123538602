import * as DateHelper from '../Helper/DateHelper.js';

/**
 * Boils down an given array to a single object
 * @param {Array} groupData - Array with elements to be grouped
 */
const getGroupedData = function (groupData, newOpId) {
  let amountSum = 0;
  let valueSum = 0;
  let valueSumAPI = 0;
  let opCode = newOpId;
  groupData.forEach(elem => {
    amountSum += elem.Amount;
    //In the original File FiatValue means the calculated value, not the price
    valueSum += elem['FiatValue'];
    valueSumAPI += elem.Amount * elem['FiatPriceAPI'];
  });
  if (newOpId.length === 0) {
    opCode = groupData[0].Operation;
  }
  return {
    Date: groupData[0].Date,
    Month: groupData[0].Date.getMonth() + 1,
    Operation: opCode,
    Cryptocurrency: groupData[0].Cryptocurrency,
    Amount: amountSum,
    Value: valueSum,
    ValueAPI: valueSumAPI,
    Currency: groupData[0]['FiatCurrency'],
    CurrencyAPI: groupData[0]['FiatCurrencyAPI'],
  };
};

/**
 * Gets the data gouped by month
 * @param {Array} data
 * @param {String} opCodeVisual
 */
const getMonthlyGroupedData = function (data, opCodeVisual) {
  let groupArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  DateHelper.getListOfMonth(data).forEach(el => {
    let erg = DateHelper.getListForMonth(data, el);
    const entry = getGroupedData(erg, opCodeVisual);
    groupArr[entry.Month - 1] = entry;
  });

  return groupArr;
};

/**
 * Gets the array with the monthly grouped data
 * @param {Array} data - array with staking data
 * @param {Number} year - Year to be filtered
 * @param {Array} operations - OperationIds to be filtered
 * @param {Array} opCodeVisual - Operation to be shown
 */
const getSummaryForTransactions = function (
  data,
  year,
  operations,
  opCodeVisual
) {
  let newArr = [];
  operations.forEach(op => {
    const erg = data.filter(el => el.Operation === op);
    newArr = newArr.concat(erg);
  });

  const yearDate = DateHelper.getElementsByYear(newArr, year);
  const filteredArr = getMonthlyGroupedData(yearDate, opCodeVisual);

  return filteredArr;
};

/**
 * Produces an Array with sums of different operations
 * @param {Array} data - staking data array
 * @param {Number} year - Year to be filtered
 */
export const sumRewards = function (data, year) {
  //Folgende Transaktionstypen sollen aufgelistet werden
  const ergArr = [];
  //Staking Rewards
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Staking reward', 'blockReward'],
      'Staking Reward'
    )
  );
  //Liquidity Mining Rewards
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      [
        'Rewards',
        'Commission',
        'Liquidity mining reward ETH-DFI',
        'Liquidity mining reward BTC-DFI',
        'Liquidity mining reward USDT-DFI',
        'Liquidity mining reward LTC-DFI',
        'Liquidity mining reward DOGE-DFI',
        'Liquidity mining reward BCH-DFI',
        'Liquidity mining reward USDC-DFI',
      ],
      'Liquidity Mining Rewards'
    )
  );
  //Lapis Rewards
  ergArr.push(
    getSummaryForTransactions(data, year, ['Lapis reward'], 'Lapis Reward')
  );
  //Lapis Rewards
  ergArr.push(
    getSummaryForTransactions(data, year, ['Lending reward'], 'Lending Reward')
  );

  //Lapis DFI Bonus
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Lapis DFI Bonus'],
      'Lapis DFI Bonus'
    )
  );
  //Lapis DFI Bonus
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Lending DFI Bonus'],
      'Lending DFI Bonus'
    )
  );
  //Bonus / Airdrops
  ergArr.push(
    getSummaryForTransactions(data, year, ['Bonus/Airdrop'], 'Bonus/Airdrop')
  );
  //Signup Bonus
  ergArr.push(
    getSummaryForTransactions(data, year, ['Signup bonus'], 'Signup Bonus')
  );
  //Referral Signup Bonus
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Referral signup bonus'],
      'Referral Signup Bonus'
    )
  );
  //Confectionery Lapis DFI Bonus
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Confectionery Lapis DFI Bonus'],
      'Conf. Lapis Bonus'
    )
  );
  //Confectionery Lapis DFI Bonus
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Confectionery Lending DFI Bonus'],
      'Conf. Lending Bonus'
    )
  );
  //Referral Reward
  ergArr.push(
    getSummaryForTransactions(data, year, ['Referral reward'], 'Refer. Reward')
  );
  //Freezer reward
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      [
        'Freezer staking bonus',
        '10 years freezer reward',
        '5 years freezer reward',
      ],
      'Freezer Bonus'
    )
  );
  //Freezer reward
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Freezer liquidity mining bonus'],
      'Freezer LM Bonus'
    )
  );

  return ergArr;
};
