import View from './View.js';
import * as domElems from './DOMElements.js';

class ConverterView extends View {
  _parentElement = domElems.logger;
  _errorMessage = 'Something went wrong while generating the report';
  _message = '';

  addHandlerDownloadReport(handler) {
    domElems.btnDownloadReport.addEventListener('click', function (evt) {
      evt.preventDefault();
      handler();
    });
  }

  addHandlerGenerateOverview(handler) {
    domElems.selectOverviewYear.addEventListener('change', function (evt) {
      evt.preventDefault();
      handler();
    });
  }

  addHandlerSelectTooling(handler) {
    domElems.toolSelected.addEventListener('change', function (evt) {
      evt.preventDefault();
      handler();
    });
  }

  addHandlerDragOver() {
    domElems.dragZone.addEventListener('dragover', function (evt) {
      evt.stopPropagation();
      evt.preventDefault();
      evt.dataTransfer.dropEffect = 'copy';
    });
  }

  addHandlerDropFile(handler) {
    domElems.dragZone.addEventListener('drop', function (evt) {
      evt.stopPropagation();
      evt.preventDefault();
      handler(Array.from(evt.dataTransfer.files));
    });
  }

  addHandlerUploadFile(handler) {
    //Step 1: Click-handler for the drop zone
    domElems.dragZone.addEventListener('click', function (evt) {
      evt.preventDefault();
      if (domElems.fileElem) {
        domElems.fileElem.click();
      }
    });
    //Step 2: Change-Handler for the fileElem
    domElems.fileElem.addEventListener('change', function (evt) {
      handler(Array.from(domElems.fileElem.files));
    });
  }

  getReportTool() {
    return document.querySelector('#which-report').value;
  }

  getReportYear() {
    return document.querySelector('#which-year-ct').value;
  }
  getReportGrouping() {
    return document.querySelector('#group-id').value;
  }
  getOverviewYear() {
    return document.querySelector('#which-year').value;
  }

  getUseToken() {
    const erg = document.querySelector('#use-token').value;
    if (erg === 'yes') {
      return true;
    }
    return false;
  }

  setUseToken(usetoken) {
    document.querySelector('#use-token').value = usetoken;
  }
  initStickyNav() {
    //-----------------------------------------------------------------------------
    // Sticky navigation: Intersection Observer API
    //-----------------------------------------------------------------------------
    const navHeight = domElems.nav.getBoundingClientRect().height;

    const stickyNav = function (entries) {
      const [entry] = entries;
      // console.log(entry);

      if (!entry.isIntersecting) domElems.nav.classList.add('sticky');
      else domElems.nav.classList.remove('sticky');
    };

    const headerObserver = new IntersectionObserver(stickyNav, {
      root: null,
      threshold: 0,
      rootMargin: `-${navHeight}px`,
    });

    headerObserver.observe(domElems.header);
  }
  downloadReport(data, toolid) {
    //Download
    const tdy = new Date(Date.now());
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);

    hiddenElement.target = '_blank';
    hiddenElement.download = `${toolid}_report_${tdy.getFullYear()}_${String(
      tdy.getMonth() + 1
    ).padStart(2, 0)}_${String(tdy.getDate()).padStart(2, 0)}.csv`;
    hiddenElement.click();
  }
}

export default new ConverterView();
