/**
 * This function returns the lowest block height in the given data
 * @param {Array} data - Array with data to be checked
 * @returns the lowest block height
 */
const getLowestBlock = function (data) {
  const blockArr = [];
  data.forEach(el => {
    blockArr.push(el.Block);
  });
  blockArr.sort((a, b) => a - b);
  return blockArr[0];
};

/**
 * This function returns the heighest block in the given data
 * @param {Array} data - Array with the data to be checked
 * @returns
 */
const getHighestBlock = function (data) {
  const blockArr = [];
  data.forEach(el => {
    blockArr.push(el.Block);
  });
  blockArr.sort((a, b) => b - a);
  return blockArr[0];
};

/**
 * This functions gets an array of block information for the give count of blocks
 * @param {Number} nrBlocks - Maximum number of blocks which should be returned
 * @returns the Array with the information about the requested blocks
 */
const getBlockInfo = async function (nrBlocks) {
  try {
    const reqString = `https://mainnet-api.defichain.io/api/DFI/mainnet/block?limit=${nrBlocks}&anchorsOnly=false`;
    const respInfo = await fetch(reqString);
    if (!respInfo.ok) {
      throw new Error(`Problem fetching the Block-Info for Block: ${blockNr}`);
    }
    const dataBlock = await respInfo.json();
    return dataBlock;
  } catch (err) {
    console.error(err);
    alert(`Something went wrong getting Block Infos\n ${err.message}`);
    throw err;
  }
};

/**
 * This function adds the Timestamp to given data based on the block height
 * @param {Array} data - Data array with all information
 */
export const addDateToData = async function (data) {
  const lowestBlock = getLowestBlock(data);
  const actualBlock = await getBlockInfo(1);
  const limit = actualBlock[0].height - lowestBlock + 200;
  const blockInfos = await getBlockInfo(limit);
  data.forEach(el => {
    const elem = blockInfos.find(it => it.height === el.Block);
    el.Date = new Date(elem.time);
  });
};
