import * as model from './Model/model.js';
import converterView from './Views/ConverterView.js';
import summaryView from './Views/SummaryView.js';
import roadmapView from './Views/RoadmapView.js';
import statsView from './Views/StatsView.js';
import * as priceAPI from './Model/Pricing/TokenPrices.js';
import * as cfg from './Model/Helper/config.js';

/**
 * Handles the DownloadReport part of the website
 */
const controlDownloadReport = async function () {
  try {
    //1.) Check if files are loaded
    if (model.state.fileCount === 0) {
      converterView.renderAlert('Please upload files first!');
      return;
    }
    converterView.renderSpinner();

    //2.) Get the report configuration
    model.state.reportTool = converterView.getReportTool();
    model.state.yearOfReport = converterView.getReportYear();
    model.state.groupingType = converterView.getReportGrouping();
    model.state.useToken = converterView.getUseToken();

    //3.) Check for unsupported transactions
    const unsupTX = model.getUnsupportedTransactions();
    if (unsupTX.length > 0) {
      converterView.renderAlert(unsupTX);
    }

    //4.) Generate the report
    await model.generateCointrackerReport();
    //5.) Download the report
    if (model.state.reportTool !== 'Accointing') {
      converterView.downloadReport(
        model.state.csvReport,
        model.state.reportTool
      );
    }
    //6.) Render the result
    converterView.renderMessage(`Report generated...`);
  } catch (err) {
    converterView.renderError();
    console.error(err);
  }
};

/**
 * Handles the Part for generating the earnings summary
 */
const controlGenerateOverview = function () {
  try {
    //1.) Check if files are loaded
    if (model.state.fileCount === 0) {
      converterView.renderAlert('Please upload files first!');
      return;
    }
    summaryView.renderSpinner();

    //2.) Get the configuration of the overview to be generated
    const yearOfReport = converterView.getOverviewYear();

    //3.) Get Summary Data
    model.generateSummaryData(yearOfReport);

    //4.) Render Result
    summaryView.renderResult(
      model.state.sumArray,
      model.getFiatCurrency(model.state.currency),
      model.isAPIUsed(model.state.currency),
      yearOfReport
    );
  } catch (err) {
    summaryView.renderError();
    console.error(err);
  }
};

/**
 * This function is called whenever one or multiple files are dropped to the dropzone or are uploaded via click on the dropzone
 */
const controlDropFile = async function (files) {
  try {
    model.clearState();
    converterView.renderSpinner();
    summaryView.clearView();

    //1) Get uploaded filed
    if (!files) return;

    //2) Process files
    await model.loadFiles(files);

    //3) Get the global currency configuration
    model.state.currency = converterView.getFIATCurrency();

    //4) Get historical prices
    await model.getPriceFeed(model.state.currency);

    //5) Separate Coins
    model.separateCoins();

    //6) Add Prices to data
    model.addPricefeedToDataset();

    //7) Show the count of files succesful loaded
    converterView.renderMessage(`${model.state.fileCount} File(s) loaded!`);

    controlGenerateOverview();
  } catch (err) {
    converterView.renderError();
    console.log(err);
  }
};

const controlSelectedTool = function () {
  if (converterView.getReportTool() === 'Cointracking') {
    converterView.setUseToken('yes');
  } else {
    converterView.setUseToken('no');
  }
};

/**
 * Initializes the pricefeed updater
 */
const initPricefeedUpdater = async function () {
  //Initial Call to get the prices
  let erg = await priceAPI.getLMPriceFeed(statsView.getFIATCurrency());
  statsView.updatePriceFeed(
    erg,
    cfg.FIAT_CURRENCY_MAP.get(statsView.getFIATCurrency())
  );
  //Set up the 60s interval to update the price feed
  let priceUpdater = setInterval(async () => {
    let erg1 = await priceAPI.getLMPriceFeed(statsView.getFIATCurrency());
    statsView.updatePriceFeed(
      erg1,
      cfg.FIAT_CURRENCY_MAP.get(statsView.getFIATCurrency())
    );
  }, 60000);
};

/**
 * Init-Function which is called when the webpage is loaded
 */
const init = function () {
  converterView.addHandlerDownloadReport(controlDownloadReport);
  converterView.addHandlerGenerateOverview(controlGenerateOverview);
  converterView.addHandlerDragOver();
  converterView.addHandlerDropFile(controlDropFile);
  converterView.addHandlerUploadFile(controlDropFile);
  roadmapView.initSlider();
  converterView.initStickyNav();
  converterView.initHeaderNavigation();
  converterView.initFooterNavigation();
  converterView.initModal();
  converterView.addHandlerSelectTooling(controlSelectedTool);
  initPricefeedUpdater();
};

//Here we go
init();
