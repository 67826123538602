/**
 * Gets the health information for the DFI Wallet on Bittrex from the official API
 * @returns the health information for the bittrex dfi wallet
 */
export const getWalletStateBittrex = async function () {
  try {
    const apiCall = `https://cors.bridged.cc/https://bittrex.com/api/v2.0/pub/currencies/GetWalletHealth`;
    const resData = await fetch(apiCall, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
    if (!resData.ok)
      throw new Error(`Problem getting status data!\n ${resData.ok}`);
    const result = await resData.json();
    const healthArr = result.result;
    const erg = healthArr.find(el => el.Health.Currency === 'DFI');
    return erg;
  } catch (err) {
    console.error(err);
    alert(`Something went wrong\n ${err.message}`);
    throw err;
  }
};

/**
 * Gets the health information for the DFI Wallet on Kucoin from the official API
 * @returns the health information for the Kucoin dfi wallet
 */
export const getWalletStateKucoin = async function () {
  try {
    const apiCall = `https://cors.bridged.cc/https://api.kucoin.com/api/v1/currencies/DFI`;
    const resData = await fetch(apiCall, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
    if (!resData.ok)
      throw new Error(`Problem getting status data!\n ${resData.ok}`);
    const result = await resData.json();
    const erg = result.data;
    return erg;
  } catch (err) {
    console.error(err);
    alert(`Something went wrong\n ${err.message}`);
    throw err;
  }
};

/**
 * Gets all information about the Liquidity Mining Pool Pairs from the defichain API
 * @returns the data with all pool pair infos
 */
export const getPoolPairInfos = async function () {
  try {
    const apiCall = `https://api.defichain.io/v1/listpoolpairs`;
    const resData = await fetch(apiCall);
    if (!resData.ok)
      throw new Error(`Problem getting status data!\n ${resData.ok}`);
    const dataStatus = await resData.json();
    return dataStatus;
  } catch (err) {
    console.error(err);
    alert(
      `Something went wrong fetching the Pool-Pair Infos - \n ${err.message}`
    );
    throw err;
  }
};

/**
 * Gets all Prices from the defichain price oracle
 * @returns the data with all prices supported from defichain
 */
export const getOraclePrices = async function () {
  try {
    const apiCall = `https://ocean.defichain.com/v0/mainnet/prices`;
    const resData = await fetch(apiCall);
    if (!resData.ok)
      throw new Error(`Problem getting status data!\n ${resData.ok}`);
    const prices = await resData.json();
    return prices.data;
  } catch (err) {
    console.error(err);
    alert(
      `Something went wrong while fetching data from price oracle - \n ${err.message}`
    );
    throw err;
  }
};
